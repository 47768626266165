import React, { Component } from 'react';
import { ReactComponent as LogoVisa } from '../../../../../assets/visa-ico.svg';
import { ReactComponent as LogoMc } from '../../../../../assets/mastercard-ico.svg';
import { ReactComponent as LogoAmex } from '../../../../../assets/amex-ico.svg';
import { ReactComponent as PagoEfectivo } from '../../../../../assets/pagoefectivo-logo.svg';
import { ReactComponent as IbkLogo } from '../../../../../assets/ibk-logo.svg';
import { ReactComponent as Dividelo } from '../../../../../assets/dividelo-logo.svg';
import { ReactComponent as Plin } from '../../../../../assets/plin.svg';
import { ReactComponent as Miles } from '../../../../../assets/millas-logo.svg';
import './CardComponent.scss';

class CardComponent extends Component {

  static CardDetails = ({ title }) => (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100%", width: "100%", justifyContent: "space-around" }}>
      <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
        <IbkLogo width={66} />
      </div>
      <h2>{title}</h2>
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", width: "100%" }}>
        <LogoVisa width={30} style={{ marginRight: '5px' }} />
        {
          (title === 'Tarjetas de Crédito') &&
          <LogoAmex width={30} />
        }
      </div>
    </div>
  );

  static CardOtherDetails = ({ title }) => (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100%", width: "100%", justifyContent: "space-around" }}>
      <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
        {/* <IbkLogo width={66} /> */}
      </div>
      <h2>{title}</h2>
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", width: "100%" }}>
        <LogoVisa width={30} />
        <LogoMc width={30} />
        <LogoAmex width={30} />
      </div>
    </div>
  );

  static FreeInstallments = ({ title }) => (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100%", width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
        <IbkLogo width={66} />
      </div>
      <h2 style={{ width: 100, paddingTop: 10 }}>{title}</h2>
    </div>
  );

  renderRadioContent(type, title) {
    switch (type) {
      case 'credit':
      case 'debit':
        return <CardComponent.CardDetails title={title} />
      case 'other':
        return <CardComponent.CardOtherDetails title={title} />;
      case 'installments':
        return <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100%", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
            <IbkLogo width={66} />
          </div>
          <Dividelo style={{ marginTop: "9px" }} />
        </div>
      case 'freeinstallments':
        return <CardComponent.FreeInstallments title={title} />
      case 'miles':
        return <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100%", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
            <IbkLogo width={66} />
          </div>
          <Miles style={{ marginTop: "3px" }} />
        </div>
      case 'plin':
        return <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          <Plin />
        </div>;
      case 'pagoefectivo':
        return <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          <PagoEfectivo width={250} />
        </div>
      case 'ibkapp':
        return <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100%", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
            <IbkLogo width={66} />
          </div>
          <h2 style={{ paddingTop: 12 }}>{title}</h2>
        </div>
      default:
        return null;
    }
  }

  render() {
    const { isSelected, title, type, onChange, id } = this.props;
    return (
      <div className={`card-container ${isSelected ? 'selected' : ''}`}>
        <div className="card-content">
          <div className="radio-button">
            <input
              type="radio"
              id={id}
              name="card-selection"
              checked={isSelected}
              onChange={onChange}
            />
            <label htmlFor={id}></label>
          </div>
          {this.renderRadioContent(type, title)}
        </div>
      </div>
    );
  }
}

export default CardComponent;
