import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import Autocomplete from 'react-google-autocomplete';

import valid from 'card-validator';
import * as $ from 'jquery';
import 'bootstrap';
import "./Checkout.scss"

import { DISTRICTS, PROVINCES, DEPARTMENTS } from './../../data/ubigeos/ubigeos';
import { IBK_BINES } from './../../data/bines/bines';
import { KEYS } from './../../constants/index';
import { Kushki } from '@kushki/js';
import { ibkMiles } from "./reducers/checkout";


class CheckoutView extends React.Component {
	constructor(props) {
		super(props);
		this.handleChangeDeliveryDate = this.handleChangeDeliveryDate.bind(this);
		this.openModal = this.openModal.bind(this);
		this.selectStore = this.selectStore.bind(this);
	}

	state = {
		products: [],
		email: "",
		validEmail: false,
		user: {
			data: {
				email: "",
				firstName: "",
				lastName: "",
				identificationNumber: "",
				phone: ""
			},
			isValid: false,
			editing: true
		},
		company: {
			data: {
				entityName: "",
				entityIdentificationNumber: ""
			}
		},
		shippingAddress: {
			view: "",
			list: [],
		},
		shipping: {
			data: {
				addressType: "residential",
				addressId: "123",
				department: "0",
				province: "0",
				district: "0",
				addressLine: "",
				addressNumber: "",
				flatNumber: "",
				reference: "",
				deliveryDate: new Date(),
				pickupDate: new Date(),
				deliveryTime: "",
				countryISO: "PER",
				postalCode: "",
				selectedDeliveryWindow: null,
				deliveryWindow: [],
				deliveryScheadule: [],
				geoCoordinates: []
			},
			items: [],
			isValid: false,
			editing: true,
			editingDefault: true,
			loadedFromStorage: false
		},
		payment: {
			data: {
				type: 1,
				paymentSystem: 2,
				cardNumber: "",
				cardType: "visa",
				placeHolder: "",
				placeHolderDocumentNumber: "",
				cvv: "",
				unmaskedCvv: "",
				year: "0",
				month: "0",
				installments: "1",
				installmentAmount: 0,
				installmentOptions: []
			},
			isValid: false,
			isIbkCard: '',
			showIsIbkCardMessage: false,
			editing: true,
			validCard: '',
			cardLength: 16,
			cvvLength: 3,
			capture: false
		},
		miles: {
			data: {
				total: 0,
				selected: 0,
				exchange: 0
			},
			loaded: false
		},
		auth: {
			data: {
				creditCard: "",
				documentType: "",
				documentNumber: "",
				password: ""
			}
		},
		billing: {
			data: {
				department: "0",
				province: "0",
				district: "0",
				addressLine: "",
				addressNumber: "",
				reference: "",
				postalCode: "150101"
			},
			isValid: false,
			editing: true,
			validCard: '',
			cardLength: 16,
			cvvLength: 3
		},
		useShippingAddress: true,
		ubigeos: {
			departments: DEPARTMENTS,
			provinces: [],
			districts: []
		},
		billingUbigeos: {
			departments: DEPARTMENTS,
			provinces: [],
			districts: []
		},
		policies: {
			personalData: false,
			terms: false,
			privacy: false,
		},
		orderType: 'boleta',
		storesModal: {
			mode: '',
			selectedAddress: null,
			selectedStore: null,
			pickUpStore: [],
			pickUpStoreDateRange: {
				min: new Date(),
				max: new Date()
			},
			searchedAddress: null,
			pickUpDateScheadule: [],
			defaultLat: -12.0986256,
			defaultLong: -77.0703135
		},
		selectedStore: null,
		dataLayerEventItems: [],
		token: null
	};

	componentDidMount = async () => {
		const { checkoutStart, match } = this.props;
		let id = match.params.id;
		await checkoutStart(id);
		this.initFloatingCart();
		this.getPreviousState();
		// this.getMilesToken();

		let k = {
			merchantId: KEYS.kushkiId,
			inTestEnvironment: KEYS.isTesting,
			regional: false
		}

		let ki = {
			merchantId: KEYS.kushkiIdInstallments,
			inTestEnvironment: KEYS.isTesting,
			regional: false
		}

		window.kushki = new Kushki(k);
		window.kushkiInstallments = new Kushki(ki);
		window['_fs_namespace'] = window['FS'];
	}

	getMilesToken = async () => {
		const { getMilesToken } = this.props;
		await getMilesToken();
		const { milesToken } = this.props;
		this.setState({
			token: milesToken.data
		});
	}

	showMilesLoginForm = () => {
		let t = null;
		let src = "";
		const { token } = this.state;

		if (token) {
			src = "https://authinterbankbenefitdev.azureedge.net/auth/authorize/v1/index.html?client_id=RAPPI&redirect_uri=https://checkoutdev.shopstar.pe/auth/interbank/callback&cancel_uri=https://checkoutdev.shopstar.pe/auth/interbank/callbackError&scope=login&response_mode=app_message&token=" + token.token.access_token;
		}


		window.onmessage = e => {
			// e.data = {code: 87e85bd4bfda4e08aac96a52d232cabb}
			// tu código aqui.
			// console.log(e);
		}

		return (<React.Fragment><iframe src={src} frameBorder="0"></iframe></React.Fragment>);
	}

	saveDataLayerEvent = (event) => {
		if (window["dataLayer"]) {
			window["dataLayer"].push(event);
		}
	}

	saveDataLayerStepEvent = (step, stepName) => {
		const { get } = this.props;
		const { dataLayerEventItems } = this.state;

		let items = [];

		if (dataLayerEventItems.length === 0 && get && get.draft) {
			let externalCart = get.draft.externalCart;
			externalCart.forEach((el) => {
				let cart = JSON.parse(el.externalJsonCart);
				let cartItems = cart.items;
				cartItems.forEach((item) => {
					let brandName = item.additionalInfo ? item.additionalInfo.brandName : '';
					let categoryName = "";
					let categoryTree = item.productCategories;
					let price = item.price / 100;
					let miles = price * 100 / 3;

					for (let k in categoryTree) {
						categoryName = categoryTree[k];
					}

					items.push({
						id: item.id,
						name: item.name,
						price: price,
						brand: brandName,
						category: categoryName,
						variant: '(not available)',
						quantity: item.quantity,
						millas: miles,
						precioIBK: '(not available)',
						vendidoPor: cart.sellers ? cart.sellers[0].name : '',
						stock: '(not available)',
					})
				});
			});
		} else {
			items = dataLayerEventItems;
		}

		this.setState({ dataLayerEventItems: items });

		let event = {
			'event': 'checkout',
			'category': 'Ecommerce',
			'action': 'Checkout - ' + stepName,
			'label': 'Pago de clientes Interbank',
			'ecommerce': {
				'currencyCode': 'PEN',
				'checkout': {
					'actionField': { 'step': step },
					'products': items
				}
			}
		}

		this.saveDataLayerEvent(event);
	}

	fsIdentify = () => {
		const { user } = this.state;

		if (window['_fs_namespace'] && window['_fs_namespace']['identify']) {
			window['_fs_namespace']['identify'](user.data.identificationNumber, {
				displayName: user.data.firstName + ' ' + user.data.lastName,
				email: user.data.email
			});
		}
	}

	getPreviousState = () => {
		let state = JSON.parse(window.localStorage.getItem("__state__"));
		let customerId = window.localStorage.getItem("__customerId__");
		let loginTime = window.localStorage.getItem("__ibkLoginTime__");

		if (loginTime) {
			var now = new Date();
			loginTime = new Date(loginTime);

			var sessionTime = (now - loginTime) / (1000 * 60);

			if (sessionTime > 60) {
				window.localStorage.removeItem("__customerId__");
				window.localStorage.removeItem("__ibkLoginTime__");
				customerId = null;
				state.payment.data.type = 1;
			}
		}

		if (state) {
			const { email, validEmail, user, company, shipping, payment, miles, auth, billing, ubigeos, billingUbigeos, orderType, storesModal, selectStore } = state;
			let p = payment;
			let s = shipping;

			s.loadedFromStorage = true;

			if (!customerId) {
				p.data.type = 1;
			} else {
				p.data.type = 2;
			}

			this.setState({ email, validEmail, user, company, shipping: s, payment: p, miles, auth, billing, ubigeos, billingUbigeos, orderType, storesModal, selectStore });
			this.ibkAuthenticate();
		}
	}

	initFloatingCart = () => {
		$(window).scroll(function () {
			let offset = $(".checkout__summary").offset();
			let stepsHeight = 0;

			if ($(window).height() <= 768)
				return;

			if (!offset)
				return;

			$(".checkout__step").each(function (i, el) {
				if (el)
					stepsHeight += $(el).height();
			})

			if ($(window).scrollTop() > offset.top && $(".checkout__summary").parent().height() <= stepsHeight + 100) {
				let diff = $(window).scrollTop() - offset.top;
				$(".checkout__summary").children(".card").css("top", diff + "px");
			} else {
				$(".checkout__summary").children(".card").css("top", "0px");
			}
		})
	}

	validateField = (type, name, value) => {
		return true;
	}

	handleEmailChange = event => {
		event.preventDefault();
		let value = event.target.value;
		let type = event.target.getAttribute("type");
		let name = event.target.getAttribute("name")
		let validation = this.validateField("email", value);

		if (!validation.isValid) {
			$(`[name=${name}]`).addClass("is-invalid");
		} else {
			$(`[name=${name}]`).removeClass("is-invalid");
		}

		if (this.validateField(type, name, value)) {
			this.setState({ email: value });
		}
	}

	showToastr = (type, message) => {
		$("#warningToast").toast({
			delay: 1500
		});
		if (message != "") {
			$("#warningToast").find(".toast-body").html(message);
		}
		$("#warningToast").toast('show');
	}

	handleContinueSubmit = async (event) => {
		event.preventDefault();
		const { checkoutPost, start } = this.props;

		let items = [];
		let draftId = "";

		let hasErrors = this.verifyErros("#continueForm");

		if (hasErrors || this.state.email === "") {
			this.showToastr("", "");
			return;
		}

		if (start.data) {
			start.data.items.forEach((el, i) => {
				items.push({
					productId: el.productId,
					quantity: el.quantity,
					seller: el.sellerName
				});
			});

			draftId = start.data.draftId;
		}

		await checkoutPost({
			email: this.state.email,
			draftId: draftId,
			items: items
		});

		this.saveDataLayerEvent({
			'event': 'virtualPage',
			'pagePath': '/Checkout/IBK/1/Datos-personales',
			'pageName': 'Checkout - Interbank - Datos personales'
		});

		const { get } = this.props;

		let user = this.state.user;
		let shipping = this.state.shipping;
		let billing = this.state.billing;
		let shippingAddress = this.state.shippingAddress;

		user.data.email = get.draft.customerEmail || "";
		user.data.firstName = get.draft.customerFirstName || "";
		user.data.lastName = get.draft.customerLastName || "";
		user.data.phone = get.draft.customerPhone || "";
		user.data.identificationNumber = get.draft.customerIdentificationNumber || "";

		if (get.draft.customerIdentificationNumber && get.draft.customerIdentificationNumber !== "") {
			this.fsIdentify();
		}

		let additionalAddress = get.draft.additionalAddress;
		if (additionalAddress) {
			additionalAddress = additionalAddress.filter((el, i) => {
				return !(el.stateCode === "150101" && el.district !== "Lima");
			});

			additionalAddress = additionalAddress.filter((el, i) => {
				return el.district !== '0';
			});
		} else {
			additionalAddress = [];
		}
		if (additionalAddress && additionalAddress.length > 0) {
			let selectedAddress = additionalAddress[0];

			shippingAddress.list = additionalAddress.map((el, i) => {
				return { selected: i === 0, ...el };
			});
			shippingAddress.view = "list";

			shipping.data.department = selectedAddress.department;
			shipping.data.province = selectedAddress.province;
			shipping.data.district = selectedAddress.district;
			shipping.data.addressLine = selectedAddress.addressLine;
			shipping.data.addressNumber = selectedAddress.addressNumber;
			shipping.data.reference = selectedAddress.reference;
			shipping.data.postalCode = selectedAddress.stateCode;

			this.loadProvinces(null, 'shipping');
			this.loadDistricts(null, 'shipping');

			this.setState({ shipping: shipping });

			this.getAddress();
		} else {
			shippingAddress.view = "form";
		}

		// if (get.draft.shippingStateCode && get.draft.shippingStateCode !== "") {
		// 	shipping.data.department = get.draft.shippingDepartment || "";
		// 	shipping.data.province = get.draft.shippingProvince || "";
		// 	shipping.data.district = get.draft.shippingDistrict || "";
		// 	shipping.data.addressLine = get.draft.shippingAddressLine || "";
		// 	shipping.data.addressNumber = get.draft.shippingAddressNumber || "";
		// 	shipping.data.reference = get.draft.shippingReference || "";

		// 	this.loadProvinces(null, 'shipping');
		// 	this.loadDistricts(null, 'shipping');
		// 	this.getAddress();
		// }

		if (get.draft.billingStateCode && get.draft.billingStateCode !== "") {
			billing.data.department = get.draft.billingDepartment || "";
			billing.data.province = get.draft.billingProvince || "";
			billing.data.district = get.draft.billingDistrict || "";
			billing.data.addressLine = get.draft.billingAddressLine || "";
			billing.data.addressNumber = get.draft.billingAddressNumber || "";
			billing.data.reference = get.draft.billingReference || "";
			billing.data.postalCode = get.draft.stateCode || "";

			this.loadProvinces(null, 'billing');
			this.loadDistricts(null, 'billing');
		}

		this.saveDataLayerStepEvent(1, 'Datos Personales');
		this.setState({ validEmail: true, user: user, shippingAddress: shippingAddress });
	}

	handleInputChange = (object, event) => {
		const { ubigeos, payment, miles } = this.state;
		const { get } = this.props;
		let name = event.target.getAttribute("name");
		let obj = this.state[object];
		let value = event.target.value;
		let stateObj = {};
		let validation = this.validateField(name, value);

		if (!validation.isValid) {
			value = validation.value;
			$(`[name=${name}]`).addClass("is-invalid");
		} else {
			$(`[name=${name}]`).removeClass("is-invalid");
		}

		obj.data[name] = value;
		stateObj[object] = obj;

		if (object === "payment") {
			if (name === "cardNumber" || name === "cvv") {
				var re = /^[0-9]*$/;
				if (!re.test(value))
					return;
			}

			let currYear = new Date().getFullYear();
			let currMonth = new Date().getMonth() + 1;

			if (name === "year") {
				let year = 2000 + parseInt(value)
				let month = parseInt(payment.data.month);

				if (new Date(year, month) < new Date(currYear, currMonth)) {
					obj.data["month"] = "0";
					obj.data["year"] = "0";
					stateObj[object] = obj;
					this.showToastr("", "Por favor seleccione una fecha de vencimiento de tarjeta v&aacute;lida.")
				}
			}
		}

		if (object === "shipping") {
			if (name === "district") {
				let district = ubigeos.districts.filter((e, i) => { return e.id == value })[0];
				obj.data[name] = value;
				obj.data["postalCode"] = district.ubigeo;
			} else {
				obj.data[name] = value;
			}

			stateObj[object] = obj;
		}

		if (object === "miles") {
			if (name === "selected") {
				if (value > 0) {
					if (value > miles.data.total) {
						value = miles.data.total;
					}

					if ((value / miles.data.change) > get.draft.total) {
						value = get.draft.total * miles.data.change;
					}
				} else {
					value = 0;
				}
			}

			obj.data[name] = Math.ceil(value);
			stateObj[object] = obj;
		}

		this.setState(stateObj);
	}

	onCvvBlur = () => {
		const { payment } = this.state;
		let p = payment;

		if (p.data.unmaskedCvv.indexOf('*') > -1)
			return;

		if (p.data.unmaskedCvv.length < 3)
			return;

		p.data.cvv = p.data.unmaskedCvv;
		this.setState({ payment: p });
		clearTimeout(window.maskCvvTimeout);

		window.maskCvvTimeout = setTimeout(() => {

			p.data.unmaskedCvv = p.data.unmaskedCvv.replace(/./g, '*');

		}, 2000);
	}

	validateField = (name, value) => {
		let re = "";
		let isValid = true;
		switch (name) {
			case "email":
				re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				isValid = re.test(value);
				break;
			case "phone":
				re = /^\d+$/;
				isValid = re.test(value);
				if (!isValid) {
					value = value.substring(0, value.length - 1);
				}
				if (value.length < 9)
					isValid = false;
				break;
			case "identificationNumber":
				re = /^\d+$/;
				isValid = re.test(value);
				if (!isValid) {
					value = value.substring(0, value.length - 1);
				}
				if (value.length != 8)
					isValid = false;
				break;
			case "cardNumber":
				re = /^\d+$/;
				isValid = re.test(value);
				if (!isValid) {
					value = value.substring(0, value.length - 1);
				}
				break;
			case "unmaskedCvv":
				re = /^\d+$/;
				isValid = re.test(value);
				if (!isValid) {
					value = value.substring(0, value.length - 1);
				}

				if (value.length > 4) {
					// value = value.substring(0, value.length - 1);
					isValid = false;
				}
				break;
			case "entityIdentificationNumber":
				re = /^\d+$/;
				if (!isValid) {
					value = value.substring(0, value.length - 1);
				}
				isValid = re.test(value);
				if (value.length != 11)
					isValid = false;
				break;
			default:
				break;
		}

		return {
			isValid: isValid,
			value: value
		};
	}

	formatEstimate = (e) => {
		let estimate = "";
		if (!e) return "";

		if (e.indexOf("bd") > -1) {
			estimate = e.substring(0, e.indexOf("bd")) + " días hábiles";
		} else {
			if (e.indexOf("d") > -1) {
				estimate = e.substring(0, e.indexOf("d")) + " días";
			} else {
				estimate = e + " dias";
			}
		}

		return estimate;
	}

	formatDate = (d) => {
		var days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
		var months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
		let date = new Date(d);

		return days[date.getDay()] + ', ' + date.getDate() + ' de ' + months[date.getMonth()];
	}

	formatPickupDate = (store, date) => {
		const { shipping, storesModal } = this.state;
		let stringDate = "";
		let deliveryWindows = []
		let hours = "";
		var days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
		var months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

		date = new Date(date);
		stringDate = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
		deliveryWindows = store.availableDeliveryWindows;

		for (var i = 0; i < deliveryWindows.length; i++) {
			let startDate = new Date(deliveryWindows[i].startDateUtc);
			let endDate = new Date(deliveryWindows[i].endDateUtc);
			let sDate = startDate.getFullYear() + '-' + startDate.getMonth() + '-' + startDate.getDate();
			if (stringDate === sDate) {
				let from = startDate.getHours() + 5;
				let to = endDate.getHours() + 5;

				hours = days[startDate.getDay()] + ', ' + startDate.getDate() + ' de ' + months[startDate.getMonth()] + ", desde " + from + ":00 hasta " + to + ":00";
			}
		}

		return hours;
	}

	handleChangePickupDate = (date, item) => {
		const { shipping, storesModal } = this.state;

		let s = shipping;
		let stringDate = "";
		let deliveryWindows = []
		let hours = [];

		date = new Date(date);
		stringDate = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
		deliveryWindows = item.selectedSla.availableDeliveryWindows;

		for (var i = 0; i < deliveryWindows.length; i++) {
			let startDate = new Date(deliveryWindows[i].startDateUtc);
			let endDate = new Date(deliveryWindows[i].endDateUtc);
			let sDate = startDate.getFullYear() + '-' + startDate.getMonth() + '-' + startDate.getDate();
			if (stringDate === sDate) {
				let from = startDate.getHours() + 5;
				let to = endDate.getHours() + 5;
				let price = ""

				if (deliveryWindows[i].price == 0) {
					price = "Gratis";
				} else {
					price = "S/" + deliveryWindows[i].price;
				}

				hours.push({
					id: "schdl-" + i,
					value: deliveryWindows[i].startDateUtc,
					label: "Desde " + from + ":00 hasta " + to + ":00     " + price,
					data: deliveryWindows[i]
				})
			}
		}

		item.selectedDate = new Date(date);
		item.scheadule = hours;

		s.items.forEach((el, i) => {
			if (el.store === item.store)
				el = item
		});

		this.setState({ shipping: s });
	}

	handleChangeDeliveryDate = (date, item, dws) => {
		const { shipping } = this.state;

		let s = shipping;
		let stringDate = "";
		let deliveryWindows = []
		let hours = [];

		date = new Date(date);
		stringDate = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
		deliveryWindows = dws;

		if (date) {
			let datePicker = $("#collapse-" + item.store).find(".date-picker");
			datePicker.removeClass("not-valid");
			datePicker.find("input").removeClass("is-invalid");
		}

		for (var i = 0; i < deliveryWindows.length; i++) {
			let startDate = new Date(deliveryWindows[i].startDateUtc);
			let endDate = new Date(deliveryWindows[i].endDateUtc);
			let sDate = startDate.getFullYear() + '-' + startDate.getMonth() + '-' + startDate.getDate();
			if (stringDate === sDate) {
				let from = startDate.getHours() + 5;
				let to = endDate.getHours() + 5;
				let price = ""
				if (deliveryWindows[i].price == 0) {
					price = "Gratis";
				} else {
					price = "S/" + deliveryWindows[i].price;
				}

				hours.push({
					id: "schdl-" + i,
					value: deliveryWindows[i].startDateUtc,
					label: "Desde " + from + ":00 hasta " + to + ":00",
					data: deliveryWindows[i]
				})
			}
		}

		item.selectedDeliveryDate = new Date(date);
		item.deliveryScheadule = hours;

		item.deliveryDeliveryWindow = hours[0].data;
		item.deliveryDeliveryWindowId = hours[0].id;

		s.items.forEach((el, i) => {
			if (el.store === item.store)
				el = item
		});
		this.setState({ shipping: s });
	}

	handleChangePickupTime = (e, item) => {
		const { shipping } = this.state;
		let date = e.target.value;
		let scheadule = shipping.data.deliveryScheadule;
		let s = shipping;

		item.scheadule.forEach((el, i) => {
			if (el.id == date) {
				item.deliveryWindow = el.data
			}
		});

		s.items.forEach((el, j) => {
			if (el.store === item.store) {
				el = item
			}
		});


		this.setState({ shipping: s });
	}

	handleChangeDeliveryTime = (e, item) => {
		const { shipping } = this.state;
		let date = e.target.value;
		let scheadule = item.deliveryScheadule;
		let s = shipping;

		if (date && date !== "0" && date !== "") {
			$(e.target).closest('.form-control').removeClass("not-valid");
			$(e.target).removeClass("is-invalid");
		} else {
			// $(e.target).closest('.form-control').addClass("not-valid");
			// $(e.target).addClass("is-invalid");
			return;
		}

		scheadule.forEach((el, i) => {
			if (el.id == date) {
				item.deliveryDeliveryWindow = el.data;
				item.deliveryDeliveryWindowId = el.id;
			}
		});

		s.items.forEach((el, j) => {
			if (el.store === item.store) {
				el = item
			}
		});

		this.setState({ shipping: s });
	}

	handleChangeTab = (e, tab) => {
		$('#' + tab).tab('show');
	}

	handleUserSubmit = async (event) => {
		event.preventDefault();
		const { user, orderType, company } = this.state;
		const { userPut } = this.props;

		let hasErrors = this.verifyErros("#userForm");
		let isValidUser = this.verifyObject(user.data);
		let isValidCompany = true;

		this.saveDataLayerEvent({
			'event': 'virtualEvent',
			'category': 'Checkout - Datos personales',
			'action': 'Seleccionar boton',
			'label': 'Continuar'
		});

		if (orderType === "factura") {
			isValidCompany = this.verifyObject(company.data)
		}

		if (hasErrors || !isValidUser || !isValidCompany) {
			this.showToastr("", "");
			return;
		}

		this.fsIdentify();

		this.setState({ user: user });
		await userPut(this.props.get.draft.draftId, user.data);

		this.saveDataLayerEvent({
			'event': 'virtualPage',
			'pagePath': '/Checkout/IBK/2/Metodo-de-entrega',
			'pageName': 'Checkout - Interbank - Método de entrega'
		});

		this.saveDataLayerStepEvent(2, 'Metodo de entrega');
	}

	handleOrderType = () => {
		var orderType = "";
		if (this.state.orderType == 'boleta') {
			orderType = 'factura';
		} else {
			orderType = 'boleta';
		}

		this.setState({ orderType: orderType })
	}

	handleEditUser = () => {
		const { userView } = this.props;
		userView('form');
	}

	handleEditShipping = () => {
		let shipping = this.state.shipping;

		shipping.editing = true;
		shipping.isValid = false;

		this.setState({ shipping: shipping });
	}

	handleBuyNowOld = () => {
		const { payment, billing, orderType } = this.state;
		const { get } = this.props;

		let pd = payment.data;
		let context = this;

		let userComplete = $("#userSummary");
		let shippingComplete = $("#shippingSummary");
		let hasErrors = this.verifyErros("#paymentForm");
		let isValidPayment = true;
		let isValidBilling = this.verifyObject(billing.data);

		if ($(".out-stock").length > 0) {
			this.showToastr("", "Por favor elimine los productos sin stock de su carrito.");
			return;
		}

		if (userComplete.length === 0) {
			this.showToastr("", "Por favor complete la información de usuario.");
			return;
		}

		if (shippingComplete.length === 0) {
			this.showToastr("", "Por favor complete la información de envio y/o recojo de sus productos.");
			return;
		}

		// if (orderType === "factura") {
		// 	isValidCompany = this.verifyObject(company.data)

		// 	if(!isValidCompany) {
		// 		this.showToastr("", "Por favor complete la información para emitir su factura.");
		// 		return
		// 	}
		// }

		if (this.calculateRestAmount() == 0) {
			isValidPayment = true;
		} else {
			isValidPayment = this.verifyObject(payment.data);
		}

		if (hasErrors || !isValidPayment || !isValidBilling) {
			this.showToastr("", "");
			return;
		}

		$("#loadingPaymentModal").modal("show");

		let callback = (response) => {

			if (!response.code) {
				context.registerPayment(response.token, total);
			} else {
				$("#loadingPaymentModal").modal("hide");
				$("#errorPaymentModal").modal("show");
			}
		}
		let total = get.draft.total;
		let customerId = window.localStorage.getItem("__customerId__");
		let checkoutId = window.localStorage.getItem("__checkoutId__");

		if (!checkoutId) {
			window.localStorage.setItem("__checkoutId__", get.draft.draftId);
		}

		if (payment.data.type == 2 && customerId) {
			total = this.calculateRestAmount().toFixed(2);
		}

		let kushkiModel = {
			amount: total,
			currency: "PEN",
			card: {
				name: pd.placeHolder,
				number: pd.cardNumber,
				cvc: pd.cvv,
				expiryMonth: pd.month,
				expiryYear: pd.year
			},
		}

		if (this.calculateRestAmount() == 0) {
			context.registerPayment(null, total);
		} else {
			window.kushki.requestToken(kushkiModel, callback);
		}
	}

	handleBuyNow = () => {
		const { payment, billing, orderType, company } = this.state;
		const { get } = this.props;

		let pd = payment.data;
		let context = this;

		let userComplete = $("#userSummary");
		let shippingComplete = $("#shippingSummary");
		let hasErrors = this.verifyErros("#paymentForm");
		let isValidPayment = true;
		let isValidBilling = this.verifyObject(billing.data);

		if ($(".out-stock").length > 0) {
			this.showToastr("", "Por favor elimine los productos sin stock de su carrito.");
			return;
		}

		if (userComplete.length === 0) {
			this.showToastr("", "Por favor complete la informacion de usuario.");
			return;
		}

		if (shippingComplete.length === 0) {
			this.showToastr("", "Por favor complete la informacion de envio y/o recojo de sus productos.");
			return;
		}

		if (this.calculateRestAmount() == 0) {
			isValidPayment = true;
		} else {
			isValidPayment = this.verifyObject(payment.data);
		}

		if (hasErrors || !isValidPayment || !isValidBilling) {
			this.showToastr("", "");
			return;
		}

		if (orderType === "factura") {
			let isValidCompany = this.verifyObject(company.data)

			if (!isValidCompany) {
				this.showToastr("", "Por favor complete la información para emitir su factura.");
				return
			}
		}

		if (payment.data.type == 3 && payment.data.installments == 1) {
			this.showToastr("", "Por favor seleccione el número de cuotas.");
			$(".installments-list .card").addClass("error");
			return;
		}

		$("#loadingPaymentModal").modal("show");

		this.saveDataLayerEvent({
			'event': 'virtualEvent',
			'category': 'Checkout - Metodo de pago',
			'action': 'Seleccionar boton',
			'label': 'Comprar ahora'
		});

		let callback = (response) => {

			if (!response.code) {
				context.registerPayment(response.token, total);
			} else {
				$("#loadingPaymentModal").modal("hide");
				$("#errorPaymentModal").modal("show");
			}
		}
		let total = get.draft.total;
		let customerId = window.localStorage.getItem("__customerId__");
		let checkoutId = window.localStorage.getItem("__checkoutId__");

		if (!checkoutId) {
			window.localStorage.setItem("__checkoutId__", get.draft.draftId);
		}

		if (payment.data.type == 2 && customerId) {
			total = this.calculateRestAmount().toFixed(2);
		}

		let kushkiModel = {
			amount: total,
			currency: "PEN",
			card: {
				name: pd.placeHolder,
				number: pd.cardNumber,
				cvc: pd.cvv,
				expiryMonth: pd.month,
				expiryYear: pd.year
			},
		}

		// if (this.calculateRestAmount() == 0) {
		// 	context.registerPayment(null, total);
		// } else {
		// 	window.kushki.requestToken(kushkiModel, callback);
		// }
		context.registerPayment(null, total);
	}

	registerPayment = async (creditCardTokenId, amountToAuthorize) => {
		let model = {};
		const { get, transactionPost, sendEmblueData } = this.props;
		const { user, company, shipping, payment, orderType, billing, miles, policies } = this.state;

		//Authorize Model
		const urlParams = new URLSearchParams(window.location.search);
		const flag = urlParams.get('flag');
		let capture = flag && flag === 'pendingCapture';

		let pd = payment.data;
		let customerId = window.localStorage.getItem("__customerId__");
		let checkoutId = window.localStorage.getItem("__checkoutId__");
		let milesDiscount = this.calculateMilesDiscount();

		pd.creditCardTokenId = creditCardTokenId;
		pd.amountToAuthorize = amountToAuthorize;

		if (pd.type == 2 && customerId) {
			if (milesDiscount < get.draft.total && payment.isIbkCard !== "valid") {
				setTimeout(() => {
					$("#loadingPaymentModal").modal("hide");
					$("#benefitCardErrorModal").modal("show");
				}, 500);
				return;
			}
			pd.miles = {
				value: miles.data.selected,
				customerId: customerId
			}
		}

		if (pd.type == 3) {
			pd.paymentMethodType = "payment-installments";
		} else {
			pd.paymentMethodType = "default";
		}

		if (get &&
			get.draft.shippingData &&
			get.draft.shippingData.paymentData &&
			get.draft.shippingData.paymentData.paymentSystems &&
			get.draft.shippingData.paymentData.paymentSystems.length > 0) {
			let paymentSystems = get.draft.shippingData.paymentData.paymentSystems.filter((el, i) => {
				return el.name === "Promissory"
			});
			if (paymentSystems.length > 0) {
				paymentSystems = paymentSystems[0];
				pd.paymentSystem = paymentSystems.id;
				pd.paymentSystemName = paymentSystems.name;
				pd.PaymentSystemGroup = paymentSystems.groupName;
			}
		}

		billing.data.district = billing.data.district.split('-')[0];

		model.checkOutId = get.draft.draftId;
		model.orderType = orderType;
		model.entityName = company.data.entityName;
		model.entityIdentificationNumber = company.data.entityIdentificationNumber;
		model.shipping = shipping.data;
		model.billing = billing.data;
		model.payment = pd;

		model.shipping.firstName = user.data.firstName;
		model.shipping.lastName = user.data.lastName;
		model.shipping.phone = user.data.phone;
		model.shipping.cellPhone = user.data.phone;

		model.authorizePersonalData = policies.privacy;
		model.capture = capture;

		if (policies.privacy) {
			let emblueModel = {
				"eventName": "shopstar",
				"email": user.data.email,
				"attributes": {
					"nombre": user.data.firstName,
					"apellidos": user.data.lastName,
					"telefono": user.data.phone,
					"DNI": user.data.identificationNumber,
					"AceptoTerminosCondiciones": policies.terms,
					"aceptoTerminosPagoCanje": policies.personalData,
					"aceptoUsoDeDatos": policies.privacy,
					"tag": "Checkout Millas"
				}
			};

			sendEmblueData(emblueModel);
		}

		let store = null;
		let item = null;

		let stores = shipping.items;
		let addresses = [];
		let items = [];

		// console.log(get.draft);
		// console.log(stores);
		stores.forEach((el, i) => {
			el.products.forEach((p, j) => {
				let item = null;
				let address = null;

				if (el.shippingMethod === "delivery") {
					if (!el.selectedDeliverySla) {
						let sla = p.logisticInfo.slas.filter((s, k) => {
							return s.deliveryChannel === "delivery";
						});

						sla = sla.length > 0 ? sla[0] : null;
						el.selectedDeliverySla = sla;
					}
					let vtxJson = get.draft.externalCart.filter((s, k) => {
						return s.externalSellerId === el.store;
					});
					let shippingPrice = 0;
					let productSla = [];

					if (vtxJson.length > 0) {
						vtxJson = vtxJson[0];
						let vtxShipping = JSON.parse(vtxJson.externalJsonShipping);
						if (vtxShipping && vtxShipping.shippingData && vtxShipping.shippingData.logisticsInfo) {
							vtxShipping.shippingData.logisticsInfo.forEach((lInfo, l) => {
								let slas = lInfo.slas;
								if (p.requestIndex === lInfo.itemIndex) {
									productSla = slas.filter((s, k) => {
										return p.logisticInfo.selectedSla === s.id;
									});

									if (productSla.length > 0)
										shippingPrice = productSla[0].price / 100;
								}
							})
						}
					}

					// if (p.logisticInfo.slas && p.logisticInfo.slas.length > 0) {
					// 	productSla = p.logisticInfo.slas.filter((s, k) => {
					// 		return p.logisticInfo.selectedSla === s.id;
					// 	});

					// 	if (productSla.length > 0)
					// 		shippingPrice = productSla[0].price / 100;
					// }

					item = {
						"itemIndex": p.requestIndex,
						"itemId": p.id,
						"selectedDeliveryChannel": el.shippingMethod,
						"selectedSla": el.selectedDeliverySla.id,
						"price": shippingPrice, //el.selectedDeliverySla.price / 100,
						"deliveryWindow": el.deliveryDeliveryWindow,
						"shippingEstimate": el.selectedDeliverySla.shippingEstimate,
						"lockTTL": el.selectedDeliverySla.lockTTL,
						"shippingEstimateDate": el.selectedDeliverySla.shippingEstimateDate
					}
				} else if (el.shippingMethod === "pickup-in-point") {
					item = {
						"itemIndex": p.requestIndex,
						"itemId": p.id,
						"selectedDeliveryChannel": el.shippingMethod,
						"selectedSla": el.selectedSla.id,
						"price": el.selectedSla.price / 100,
						"deliveryWindow": el.deliveryWindow,
						"shippingEstimate": el.selectedSla.shippingEstimate,
						"lockTTL": el.selectedSla.lockTTL,
						"shippingEstimateDate": el.selectedSla.shippingEstimateDate,
						"storePickUpAddress": el.address
					}
				}

				item.sellerId = el.store;
				items.push(item);
			})
		})

		model.shippingMethods = items;
		await transactionPost(model);

		const { transaction, authorizePayments, invalidateOrder } = this.props

		if (!transaction.transaction) {
			let transactionError = {
				errorName: "v4Error",
				request: model,
				error: transaction.error
			}
			$("#trackEvent").attr("data", JSON.stringify(transactionError));
			$("#trackEvent").trigger('click');
			return;
		}

		let callback = (response, order) => {
			if (response.error) {
				$("#loadingPaymentModal").modal("hide");
				$("#kushkiErrorModal").modal("show");
				$("#trackEvent").attr("data", response.message);
				$("#trackEvent").trigger('click');

				let invalidateModel = {
					checkOutId: checkoutId,
					orderGroup: transaction.transaction.orders[0].orderGroup,
					reason: response.message
				};

				invalidateOrder(invalidateModel);
				return;
			}

			let token = response.token;
			let sessionId = response.sessionId;
			let gateway = window.localStorage.getItem("__paymentGateway__");

			let kushkiPayment = {
				sessionId: sessionId,
				creditCardTokenId: token,
				cardNumber: pd.cardNumber,
				placeHolder: pd.placeHolder,
				cvv: pd.cvv,
				year: pd.year,
				month: pd.month,
				installments: pd.installments,
				cardType: pd.cardType,
				orderNumber: order.orderNumber,
				amountToAuthorize: order.total,
				paymentMethodType: pd.paymentMethodType,
				placeHolderDocumentNumber: pd.placeHolderDocumentNumber
			};

			if (gateway === 'payu') {
				if (pd.cardType === 'visa') {
					kushkiPayment.cardType = 'VISA';
				} else if (pd.cardType === 'mastercard') {
					kushkiPayment.cardType = 'MASTERCARD';
				} else if (pd.cardType === 'diners-club') {
					kushkiPayment.cardType = 'DINERS';
				} else if (pd.cardType === 'american-express') {
					kushkiPayment.cardType = 'AMEX';
				}
			}

			authorizeModel.payments.push(kushkiPayment);

			if (authorizeModel.payments.length === t.orders.length) {
				authorizeModel.gateway = gateway;
				if (capture) {
					authorizeModel.pendingCapture = true;
				}
				authorizePayments(authorizeModel);
			}
		}

		let t = transaction.transaction;
		let authorizeModel = {
			checkOutId: checkoutId,
			capture: capture,
			orderGroup: t.orders[0].orderNumber.split("-")[0],
			payments: [],
			billing: billing.data
		}

		if (t.orders && t.orders.length > 0) {
			t.orders.forEach((order, i) => {
				if (order.total > 0) {
					let paymentGateway = window.localStorage.getItem("__paymentGateway__");
					let paymentSessionId = window.localStorage.getItem("__payuSessionId__");
					let paymentModel = {
						amount: order.total,
						currency: "PEN",
						card: {
							name: pd.placeHolder,
							number: pd.cardNumber,
							cvc: pd.cvv,
							expiryMonth: pd.month,
							expiryYear: pd.year
						},
					};

					if (paymentGateway === 'payu') {
						callback({ token: '', sessionId: paymentSessionId }, order);
					} else {
						if (pd.type == 3) {
							window.kushkiInstallments.requestToken(paymentModel, (response) => {
								callback(response, order);
							});
						} else {
							window.kushki.requestToken(paymentModel, (response) => {
								callback(response, order);
							});
						}
					}
				} else {
					callback({ token: null }, order);
				}
			})
		}
	}

	calculateRestAmount = () => {
		const { get } = this.props;
		const { miles } = this.state;

		let rest = (get.draft.total - (miles.data.selected / miles.data.change));

		if (rest < 0) {
			rest = 0;
		}

		return rest;
	}


	getDeliveryDateString = (shipping) => {
		var days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
		var months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
		var d = new Date(shipping.deliveryTime);
		var dayName = days[d.getDay()];
		var monthName = months[d.getMonth()];
		return `${dayName} ${d.getDate()} de ${monthName} de ${d.getFullYear()}, desde las 09:00 hasta las 20:00`;
	}

	loadProvinces = (e, type) => {
		const { ubigeos, billingUbigeos, shipping, billing } = this.state;
		let idUbigeo = shipping.data.department;

		if (e) {
			idUbigeo = e.target.value;
		}

		if (type === 'billing')
			idUbigeo = billing.data.department;

		let provinces = PROVINCES[idUbigeo];

		if (provinces && provinces.length > 0) {
			if (type === "shipping") {
				ubigeos.provinces = provinces;
				billingUbigeos.provinces = provinces;
			} else if (type === "billing") {
				billingUbigeos.provinces = provinces;
			}

			this.setState({ ubigeos: ubigeos, billingUbigeos: billingUbigeos });
		}
	}

	loadDistricts = (e, type) => {
		// let idUbigeo = e.target.value;
		const { ubigeos, billingUbigeos, shipping, billing } = this.state;
		let idUbigeo = shipping.data.district;

		if (e) {
			idUbigeo = e.target.value;
		}

		if (type === 'billing')
			idUbigeo = billing.data.province;
		let districts = DISTRICTS[idUbigeo];

		if (districts && districts.length > 0) {
			// const { ubigeos, billingUbigeos } = this.state;

			if (type === "shipping") {
				ubigeos.districts = districts;
				billingUbigeos.districts = districts;
			} else if (type === "billing") {
				billingUbigeos.districts = districts;
			}

			this.setState({ ubigeos: ubigeos, billingUbigeos: billingUbigeos });
		}
	}

	validateIbkCardNumber = (e) => {
		const { payment } = this.state;
		let cardNumber = e.target.value;

		// if (payment.data.type == 3) {
		let bines = IBK_BINES;
		let ibkCard = 'invalid';
		bines.forEach(bin => {
			if (cardNumber.indexOf(bin) > -1) {
				ibkCard = 'valid';
			}
		});

		if (ibkCard === 'invalid') {
			$('#installmentsList').collapse('hide');
			payment.data.installments = 1;
		}

		payment.isIbkCard = ibkCard;
		payment.showIsIbkCardMessage = ibkCard != '' ? true : false;
		this.setState({ payment: payment });
		setTimeout(() => {
			payment.showIsIbkCardMessage = false;
			this.setState({ payment: payment });
		}, 3000)
		// }

		// console.log(payment);
	}

	validateCardNumber = (e) => {
		let cardNumber = e.target.value;
		let card = valid.number(cardNumber);
		let cards = {
			visa: "Visa",
			amex: "American Express",
			diners: "Diners",
			mastercard: "Mastercard"
		}
		const { payment } = this.state;
		const { get } = this.props;

		payment.validCard = card.isValid ? 'valid' : '';


		if (card.card) {
			console.log(card.card.type);
			payment.data.cardType = card.card.type;
			payment.cardLength = Math.max.apply(null, card.card.lengths);

			if (get.draft && get.draft.shippingData) {
				let paymentData = get.draft.shippingData.paymentData.installmentOptions.filter((el, index) => {
					return el.paymentName == cards[card.card.type];
				})[0];
				if (paymentData) {
					payment.data.installmentOptions = paymentData.installments;
				}
			}
		}

		if (card.code && card.code.name == 'CVV') {
			payment.cvvLength = card.code.size;
		} else {
			payment.cvvLength = 3;
		}

		this.setState({ payment: payment });
	}

	getInterbankDiscount = async () => {
		const { payment, miles } = this.state;
		const { get, getDiscount } = this.props;

		let model = {};

		if (payment.data.type == 2) {
			let bin = "998422";
			model = {
				bin: bin,
				installments: 1,
				referenceValue: get.draft.total,
				paymentSystem: 1,
				customerId: window.localStorage.getItem("__customerId__")
			}

			await getDiscount(get.draft.draftId, model);
		}
	}

	getDiscount = async (e) => {
		const { payment, miles } = this.state;
		const { get, getDiscount, getInstallments } = this.props;

		let cards = {
			visa: "Visa",
			amex: "American Express",
			diners: "Diners",
			mastercard: "Mastercard"
		}
		let model = {};

		if (e.target.value.length == 6 || (e.type === "blur" && e.target.value.length >= 6)) {

			let bin = e.target.value.substring(0, 6);
			model = {
				bin: bin,
				installments: 1,
				referenceValue: get.draft.total,
				paymentSystem: 1,
				customerId: window.localStorage.getItem("__customerId__")
			}

			if (payment.data.type == 2 && miles.data.selected > 0) {

			} else {
				let checkoutId = get.draft.draftId;
				await getDiscount(get.draft.draftId, model);
				getInstallments(checkoutId);
			}
		}
	}

	openModal = (mode, item) => {
		const { shippingMapChangeView } = this.props;

		this.setState({ selectedStore: item });
		shippingMapChangeView(mode);
		this.clearSelectedStore();
		$("#storesModal").modal("show");
	}

	getAddress = () => {
		const { getAddress, get } = this.props;
		const { ubigeos, shipping, user } = this.state;

		let items = get.draft.items;
		// let district = shipping.data.district.split('-')[0];
		// let postalCode = shipping.data.district.split('-')[1];

		let model = {
			postalCode: shipping.data.postalCode,
			address: []
		}

		items.forEach((el, i) => {
			model.address.push({
				addressId: this.newGuid(),
				addressType: i === 0 ? "residential" : "search",
				firstName: user.data.firstName,
				lastName: user.data.lastName,
				phone: user.data.phone,
				cellPhone: user.data.phone,
				addressLine: shipping.data.addressLine,
				addressNumber: shipping.data.addressNumber,
				reference: "",
				countryISO: "PER",
				postalCode: shipping.data.postalCode,
				department: shipping.data.department,
				province: shipping.data.province,
				district: shipping.data.district,
				geoCoordinates: []
			})
		})

		let address = `${shipping.data.district}, ${shipping.data.province}, ${shipping.data.department}`;
		getAddress(address, get.draft.draftId, model);
	}

	getArea = (place) => {
		let address = place.address_components;
		let areaObj = {
			address: "",
			city: "",
			country: "",
			department: "",
			district: "",
			postalCode: "",
			province: "",
			reference: "",
			addressNumber: ""
		};

		areaObj.address = place.formatted_address
		areaObj.geoCoordinates = [place.geometry.location.lng(), place.geometry.location.lat()]
		for (var i = 0; i < address.length; i++) {
			var e = address[i];

			if (e.types.indexOf("country") > -1) {
				areaObj.country = e.short_name;
			} else if (e.types.indexOf("administrative_area_level_1") > -1) {
				areaObj.department = e.short_name;
			} else if (e.types.indexOf("administrative_area_level_2") > -1) {
				areaObj.province = e.short_name;
			} else if (e.types.indexOf("locality") > -1) {
				areaObj.district = e.short_name;
			} else if (e.types.indexOf("sublocality_level_1") > -1) {
				areaObj.city = e.short_name;
			} else if (e.types.indexOf("postal_code") > -1) {
				areaObj.postalCode = e.short_name;
			} else if (e.types.indexOf("route") > -1) {
				areaObj.reference = e.short_name;
			} else if (e.types.indexOf("street_number") > -1) {
				areaObj.addressNumber = e.short_name;
			}
		}

		return areaObj;
	}

	newGuid = () => {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}

	selectAddress = (addressResult) => {
		const { shippingPut, get } = this.props;
		const { user } = this.state;

		let postalCode = "";
		let address = this.getArea(addressResult);
		let items = get.draft.items;

		let storesModal = this.state.storesModal;
		storesModal.searchedAddress = address;


		let model = {
			postalCode: "150101",
			address: []
		}

		items.forEach((el, i) => {
			model.address.push({
				addressId: this.newGuid(),
				addressType: i === 0 ? "residential" : "search",
				firstName: user.data.firstName,
				lastName: user.data.lastName,
				phone: user.data.phone,
				cellPhone: user.data.phone,
				addressLine: address.address,
				addressNumber: address.addressNumber,
				reference: address.reference,
				countryISO: "PER",
				postalCode: "150101",
				department: address.department,
				province: address.province,
				district: address.district,
				geoCoordinates: address.geoCoordinates
			})
		})

		this.setState({ storesModal: storesModal });
		shippingPut(this.props.get.draft.draftId, model, 1);
		console.log(this.state)
	}

	getStores = () => {

	}

	selectStore = (store) => {
		const { storesModal } = this.state;

		storesModal.selectedStore = store;
		storesModal.defaultLat = store.pickupStoreInfo.address.geoCoordinates[1];
		storesModal.defaultLong = store.pickupStoreInfo.address.geoCoordinates[0];

		this.setState({ storesModal: storesModal });
	}

	clearSelectedStore = () => {
		const { storesModal } = this.state;
		storesModal.selectedStore = null;

		this.setState({ storesModal: storesModal });
	}

	updateModalView = () => {
		const { shippingStatus } = this.props;
		const { storesModal } = this.state;
		if (shippingStatus.loading && shippingStatus.view === "map") {
			storesModal.mode = "map";
		}

		this.setState({ storesModal: storesModal });
	}

	exist = (array, el) => {
		for (var i = 0; i < array.length; i++) {
			if (array[i].id == el.id)
				return true;
		}
		return false;
	}

	getStoreSlas = (products) => {
		if (!products)
			return [];

		let slas = [];
		products.forEach((el, i) => {
			el.logisticInfo.slas.forEach((s, j) => {
				slas.push(s);
			})
		})

		return slas;
	}

	renderStores = () => {
		const { get } = this.props;
		const { selectedStore } = this.state;

		let stores = "";
		let slas = [];
		let pc = [];
		let items = [];

		if (get.draft.externalCart) {

			slas = [];
			if (selectedStore)
				slas = this.getStoreSlas(selectedStore.products);


			stores = slas.map((store, index) => {
				if (store.pickupStoreInfo.address)
					return (
						<a key={'s-' + index} role="button" onClick={(e) => { this.selectStore(store) }}>
							<div className="card">
								<div className="card-body">
									<div className="location-icon">
										<i className="fas fa-map-marker-alt d-block main-text"></i>
										<span>1000+ km</span>
									</div>
									<div className="store-info">
										<h4>{store.pickupStoreInfo.friendlyName}</h4>
										<p className="mb-0">{store.pickupStoreInfo.address.street} {store.pickupStoreInfo.address.number} {store.pickupStoreInfo.address.complement}</p>
										<p className="mb-0">{store.pickupStoreInfo.address.state}, {store.pickupStoreInfo.address.city}, {store.pickupStoreInfo.address.neighborhood}</p>
									</div>
								</div>
								<div className="card-footer">
									<span>Gratis</span>
									<span>Listo en hasta 1 d&iacute;a</span>
								</div>
							</div>
						</a>
					)
				else {
					return (<span key={'s-' + index}></span>);
				}
			})
		}

		return (<React.Fragment>{stores}</React.Fragment>);
	}

	// renderDiscount = () => {
	// 	const { get, start } = this.props;
	// 	let discount = null;

	// 	if (get.draft && get.draft.shippingData) {
	// 		let totalizers = get.draft.shippingData.totalizers;
	// 		if (totalizers && totalizers.length > 0) {
	// 			discount = totalizers.filter((el) => {
	// 				return el.id == "Discounts";
	// 			})
	// 		}
	// 	}

	// 	if (discount && discount.length > 0) {
	// 		discount = discount[0];

	// 		return (
	// 			<React.Fragment>
	// 				<tr>
	// 					<td>
	// 						Descuento
	// 					</td>
	// 					<td className="text-right">
	// 						S/ {(discount.value / 100).toFixed(2)}
	// 					</td>
	// 				</tr>
	// 			</React.Fragment>
	// 		);
	// 	}

	// 	return <React.Fragment></React.Fragment>;
	// }

	renderCart = () => {
		const { get, start } = this.props;
		const { shipping } = this.state;

		let cart = "";
		let items = [];

		if (!get.draft) {
			items = start.data ? start.data.items : [];
		} else {
			items = get.draft ? get.draft.items : [];
		}

		if (start.loading) {
			return (
				<tr key={'00'}>
					<td colSpan="3" className="text-center main-text">
						<i className="fas fa-spinner fa-pulse d-block"></i>
						Cargando items
					</td>
				</tr>
			)
		}

		if (items) {
			let shippingItems = (get && get.draft && get.draft.shippingData) ? get.draft.shippingData.items : [];
			items.forEach((el, k) => {
				let i = shippingItems.filter((x, j) => {
					return el.productId == x.id
				});

				if (i.length > 0)
					i = i[0];

				el.availability = i.availability;
			})

			if (get.draft && get.draft.externalCart && parseInt(shipping.data.district) > 0) {
				items.forEach((p, j) => {
					let externalCart = get.draft.externalCart;
					externalCart.forEach((c, k) => {
						let cart = JSON.parse(c.externalJsonCart);
						let index = 0;
						let cartItems = cart.items;
						let lInfo = cart.shippingData ? cart.shippingData.logisticsInfo : cart.logisticsInfo;

						cartItems.forEach((cItem, m) => {
							if (p.productId === cItem.id) {
								index = cItem.requestIndex
							}
						})

						lInfo.forEach((item, l) => {
							if (index === item.itemIndex) {

								if (!item.slas || item.slas.length === 0) {
									p.outOfStock = true;
								}
							}
						})
					})
				})
			}

			cart = items.map((item) => {
				return (
					<React.Fragment key={item.draftItemId}>
						<tr>
							<td>
								<a className="image">
									<img src={this.setHttps(item.productImage)}></img>
								</a>
							</td>
							<td className="name">
								<h4 className="name">{item.productName}</h4>
								{!item.outOfStock &&
									<p className="quantity mb-0">Cantidad:{item.quantity}</p>
								}
								{item.outOfStock &&
									<span className="out-stock mt-2 d-block">Fuera de Stock. <a role="button" onClick={(e) => { this.removeCartItem(e, item.productId) }}>Quitar del carrito</a></span>
								}
							</td>
							<td className="price">
								<span>S/ {item.price.toFixed(2)}</span>
							</td>
						</tr>
						{(item.services && item.services.length > 0) &&
							item.services.map((ad) => {
								return (
									<tr key={ad.id}>
										<td className="p-0 border-top-0"></td>
										<td className="p-0 border-top-0" colSpan="2">
											<div className="d-flex justify-content-between additional-item">
												<small>{ad.name}</small>
												<span>S/ {(ad.price).toFixed(2)}</span>
											</div>
										</td>
									</tr>
								)
							})
						}
					</React.Fragment>
				)
			})
		} else {
			return (
				<tr key={'00'}>
					<td colSpan="3" className="text-center">
						No hay Items en el carrito
					</td>
				</tr>
			)
		}

		return (<React.Fragment>{cart}</React.Fragment>);
	}

	setHttps = (url) => {
		if (url) {
			return url.replace("http:", "https:")
		}
	}

	renderProductsBySla = () => {
		const { get } = this.props;
		const { storesModal } = this.state;

		let products = "";
		let items = get.draft.items;

		return (<React.Fragment>{products}</React.Fragment>);
	}

	renderOutStockProducts = () => {
		const { get } = this.props;

		let products = "";

		if (get.draft.shippingData && get.draft.shippingData.messages.length > 0) {
			let messages = get.draft.shippingData.messages;
			let items = get.draft.items;
			let osp = [];

			messages.forEach((el, i) => {
				let p = get.draft.shippingData.items[el.fields.itemIndex];
				if (p)
					for (var i = 0; i < items.length; i++) {
						if (p.id === items[i].productId) {
							osp.push(items[i]);
						}
					}
			})

			products = osp.map((m, i) => {
				return (
					<div key={'osp-' + m.productId} className="pickup-store mt-3 mb-3">
						<div className="card">
							<div className="card-body pl-3">
								<p className="text-main">
									<i className="fas fa-info-circle mr-2" style={{ 'position': 'initial', 'fontSize': '12px' }}></i>
									El siguiente producto no cuenta con stock,
									<a role="button" onClick={(e) => { this.removeCartItem(e, m) }}><b> retirar el producto del carrito.</b></a>
								</p>
								<img src={this.setHttps(m.productImage)} className="border" />
							</div>
						</div>
					</div>
				)
			})
		}

		return (<React.Fragment>{products}</React.Fragment>)
	}

	renderPickupOnlyProducts = () => {
		const { get } = this.props;

		let logisticsInfo = null;
		let products = "";

		if (get.draft && get.draft.shippingData) {
			let onlyPickupProducts = [];
			if (get.draft.shippingData.shippingData) {
				logisticsInfo = get.draft.shippingData.shippingData.logisticsInfo;
			} else {
				logisticsInfo = get.draft.shippingData.logisticsInfo;
			}

			logisticsInfo.forEach((item, index) => {
				if (item.deliveryChannels && item.deliveryChannels.length === 1 && item.deliveryChannels[0].id === "pickup-in-point") {
					onlyPickupProducts.push(item);
				}
			})

			if (onlyPickupProducts.length > 0) {
				let items = get.draft.items;
				let opp = [];
				onlyPickupProducts.forEach((el, i) => {
					let p = get.draft.shippingData.items[el.itemIndex];
					for (var i = 0; i < items.length; i++) {
						if (p.id === items[i].productId) {
							opp.push(items[i]);
						}
					}
				})

				products = (
					<div className="pickup-store mt-3 mb-3">
						<div className="card">
							<div className="card-body pl-3">
								<p className="text-main mb-0 main-text">
									<i className="fas fa-info-circle mr-2" style={{ 'position': 'initial', 'fontSize': '12px' }}></i>
									Este producto solo esta disponible para retirarse en tienda
							<br />
									<button type="button" className="btn btn-sm btn-main d-block mt-1 mb-2 ml-3 pl-4 pr-4" style={{ 'position': 'static' }} onClick={(e) => { this.handleChangeTab(e, 'store-pickup-tab') }}>Elegir tienda</button>
								</p>

								{opp.map((el, i) => {
									return (
										<img key={'opu-' + i} src={el.productImage} className="border ml-3" />
									)
								})}
							</div>
						</div>
					</div>
				)
			}
		}
		return (<React.Fragment>{products}</React.Fragment>)
	}

	renderDeliveryOnlyProducts = () => {
		const { get } = this.props;

		let logisticsInfo = null;
		let products = "";

		if (get.draft && get.draft.shippingData) {
			let onlyPickupProducts = [];
			if (get.draft.shippingData.shippingData) {
				logisticsInfo = get.draft.shippingData.shippingData.logisticsInfo;
			} else {
				logisticsInfo = get.draft.shippingData.logisticsInfo;
			}

			logisticsInfo.forEach((item, index) => {
				if (item.deliveryChannels && item.deliveryChannels.length === 1 && item.deliveryChannels[0].id === "delivery") {
					onlyPickupProducts.push(item);
				}
			})

			if (onlyPickupProducts.length > 0) {
				let items = get.draft.items;
				let opp = [];
				onlyPickupProducts.forEach((el, i) => {
					let p = get.draft.shippingData.items[el.itemIndex];
					for (var i = 0; i < items.length; i++) {
						if (p.id === items[i].productId) {
							opp.push(items[i]);
						}
					}
				})

				products = (
					<div className="pickup-store mt-3 mb-3">
						<div className="card">
							<div className="card-body pl-3">
								<p className="text-main mb-0 main-text">
									<i className="fas fa-info-circle mr-2" style={{ 'position': 'initial', 'fontSize': '12px' }}></i>
									Este producto solo esta disponible para entrega a domicilio
								</p>

								{opp.map((el, i) => {
									return (
										<img key={'opu-' + i} src={el.productImage} className="border ml-3" />
									)
								})}
							</div>
						</div>
					</div>
				)
			}
		}
		return (<React.Fragment>{products}</React.Fragment>)
	}

	rederStoreScheadule = () => {
		const { get } = this.props;
		const { storesModal } = this.state;
		let hours = "";
		let days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];

		if (get.draft && get.draft.shippingData) {
			let store = storesModal.selectedStore;
			let pickupPoints = get.draft.shippingData.pickupPoints;
			let pickupPoint = null;
			for (var i = 0; i < pickupPoints.length; i++) {
				if (store.pickupPointId === pickupPoints[i].id) {
					pickupPoint = pickupPoints[i];
				}
			}

			hours = pickupPoint.businessHours.map((point, index) => {
				return (
					<li key={'as-' + point.DayOfWeek} className="d-flex justify-content-between border-bottom mb-1">
						<span>{days[point.DayOfWeek]}</span>
						<span>{point.OpeningTime} a {point.ClosingTime}</span>
					</li>
				)
			})
		}

		return (<React.Fragment>{hours}</React.Fragment>);
	}

	renderSelectedPickupStores = () => {
		const { get } = this.props;
		const { storesModal, shipping } = this.state;

		let stores = "";
		if (storesModal.pickUpStore.length > 0) {
			stores = storesModal.pickUpStore.map((item, i) => {
				return (
					<React.Fragment key={'sps-' + i}>
						<div className="pickup-store mt-3 mb-3">
							<div className="card">
								<div className="card-body">
									<i className="fas fa-map-marker-alt"></i>
									<h4>{item.pickupStoreInfo.friendlyName}</h4>
									<p className="mb-0">{item.pickupStoreInfo.address.street} {item.pickupStoreInfo.address.number} {item.pickupStoreInfo.address.complement}</p>
									<p className="mb-0">{item.pickupStoreInfo.address.state}, {item.pickupStoreInfo.address.city}, {item.pickupStoreInfo.address.neighborhood}</p>
									<p className="mb-0 main-text">
										<b><a role="button" onClick={(e) => { this.editPickupStore(e, item) }}>Editar</a></b>
									</p>
									<button className="btn btn-outline-main" type="button" onClick={(e) => { this.showStoresList(e, item) }}>Ver todas las tiendas</button>
								</div>
							</div>
						</div>
						<div className="row justify-content-between">
							<div className="col-md-5">
								<div className="form-group">
									<label>Fecha de Entrega*</label>
									<DatePicker
										locale="es-US"
										selected={new Date(item.pickupDate.pickupDate)}
										minDate={item.pickupDate.min}
										maxDate={item.pickupDate.max}
										onChange={(e) => { this.handleChangePickupDate(e, item) }}
									/>
									<i className="far fa-calendar-alt form-icon main-text"></i>
								</div>
							</div>
							<div className="col-md-5">
								{item.pickupDate.pickUpDateScheadule.length > 0 && (
									<React.Fragment>
										<div className="form-group">
											<label>Rango Horario*</label>
											<select className="form-control" name="deliveryTime" value={item.pickupDate.pickupDate} onChange={(e) => this.handleChangePickupTime(e, item)}>
												<option value="0">Seleccionar</option>
												{item.pickupDate.pickUpDateScheadule.map((hour) => {
													return (
														<option key={hour.value} value={hour.value}>{hour.label}</option>
													)
												})}
											</select>
										</div>
									</React.Fragment>
								)}
							</div>
						</div>
					</React.Fragment>
				)
			})
		}


		return (<React.Fragment>{stores}</React.Fragment>);
	}

	handleDeliveryDateChange = (e) => {
		const { shipping } = this.state;
		let date = e.target.value;
		let scheadule = shipping.data.deliveryScheadule;

		for (var i = 0; i < scheadule.length; i++) {
			if (scheadule[i].id === date) {
				shipping.data.selectedDeliveryWindow = scheadule[i].data;
			}
		}
		shipping.data.deliveryTime = date;
		this.setState({ shipping: shipping });
	}

	renderDeliveryWindows = () => {
		return "";
	}

	editPickupStore = (e, item) => {
		const { storesModal, selectedStore } = this.state;
		storesModal.selectedStore = item.selectedSla;

		this.setState({ storesModal: storesModal, selectedStore: item });
		$("#storesModal").modal("show");
	}

	showStoresList = (e, item) => {
		this.setState({ selectedStore: item });

		this.clearSelectedStore();
		$("#storesModal").modal("show");
	}

	handleShippingSubmit = event => {
		event.preventDefault();
		const { shipping, storesModal, user, ubigeos } = this.state;
		const { get, shippingPut, address } = this.props;

		let model = {};
		let store = null;
		let item = null;
		model.items = get.draft.shippingData.items;

		for (var i = 0; i < model.items.length; i++) {
			model.items[i].itemIndex = model.items[i].requestIndex ? model.items[i].requestIndex : i;

			for (var j = 0; j < storesModal.pickUpStore.length; j++) {
				store = storesModal.pickUpStore[j];
				for (var k = 0; k < store.items.length; k++) {
					item = store.items[k]
					if (model.items[i].id === item.id) {
						model.items[i].selectedDeliveryChannel = "pickup-in-point";
						model.items[i].addressId = "address-" + store.pickupStoreInfo.address.addressId;
						model.items[i].productId = model.items[i].id;
						model.items[i].selectedSLAId = store.id;

						if (store.availableDeliveryWindows && store.availableDeliveryWindows.length > 0) {
							model.items[i].deliveryWindow = {
								startDateUtc: new Date(store.availableDeliveryWindows[0].startDateUtc),
								endDateUtc: new Date(store.availableDeliveryWindows[store.availableDeliveryWindows.length - 1].endDateUtc)
							}
						} else {
							model.items[i].deliveryWindow = null
						}
					}
				}
			}

			if (!model.items[i].selectedDeliveryChannel) {
				let lgi = null;
				if (get.draft.shippingData.shippingData) {
					lgi = get.draft.shippingData.shippingData.logisticsInfo;
				} else {
					lgi = get.draft.shippingData.logisticsInfo;
				}

				model.items[i].selectedDeliveryChannel = "delivery";
				model.items[i].addressId = "address-delivery";
				model.items[i].productId = model.items[i].id;
				model.items[i].selectedSLAId = lgi[model.items[i].itemIndex].slas.length > 0 ? lgi[model.items[i].itemIndex].slas[0].id : "Despacho a Domicilio";
				model.items[i].deliveryWindow = shipping.data.selectedDeliveryWindow;
			}
		}

		model.address = [];
		let department = ubigeos.departments.filter((el, i) => { return el.id_ubigeo === shipping.data.department })[0];
		let province = ubigeos.provinces.filter((el, i) => { return el.id_ubigeo === shipping.data.province })[0];
		let district = ubigeos.districts.filter((el, i) => { return el.id_ubigeo === shipping.data.district })[0];

		model.items.forEach((el, i) => {
			let li = null;
			if (get.draft.shippingData.shippingData) {
				li = get.draft.shippingData.shippingData.logisticsInfo[el.itemIndex];
			} else {
				li = get.draft.shippingData.logisticsInfo[el.itemIndex];
			}

			let selectedSla = null;
			if (li.deliveryChannels.length === 1 && li.deliveryChannels[0].id === "delivery") {
				model.address.push({
					addressId: "address-delivery",
					firstName: user.data.firstName,
					lastName: user.data.lastName,
					phone: user.data.phone,
					cellPhone: user.data.phone,
					addressLine: shipping.data.addressLine,
					addressNumber: shipping.data.addressNumber,
					reference: shipping.data.reference,
					countryISO: shipping.data.countryISO,
					geoCoordinates: [
						address.data.results[0].geometry.location.lng,
						address.data.results[0].geometry.location.lat
					],
					department: department.nombre_ubigeo,
					province: province.nombre_ubigeo,
					district: district.nombre_ubigeo,
					addressType: "Residential",
					postalCode: "150101",
				})
			} else {
				selectedSla = li.slas.filter((sla, j) => {
					return sla.id = el.selectedSLAId
				})[0];

				model.address.push({
					addressId: "address-" + selectedSla.pickupStoreInfo.address.addressId,
					firstName: user.data.firstName,
					lastName: user.data.lastName,
					phone: user.data.phone,
					cellPhone: user.data.phone,
					addressLine: selectedSla.pickupStoreInfo.address.street,
					addressNumber: selectedSla.pickupStoreInfo.address.number,
					reference: shipping.data.reference,
					countryISO: shipping.data.countryISO,
					postalCode: "150101",
					department: selectedSla.pickupStoreInfo.address.state,
					province: selectedSla.pickupStoreInfo.address.city,
					district: selectedSla.pickupStoreInfo.address.neighborhood,
					geoCoordinates: selectedSla.pickupStoreInfo.address.geoCoordinates,
					addressType: "search"
				})
			}

		});

		model.postalCode = "15023";
		shippingPut(get.draft.draftId, model, 2)

		setTimeout(() => {
			shipping.editing = false;
			shipping.isValid = true;
			this.setState({ shipping: shipping });
		}, 1500);
	}

	savePickupStore = () => {
		const { shipping, storesModal, selectedStore } = this.state;
		let minDate = "";
		let maxDate = "";
		let pickedStore = {};
		let ss = selectedStore;
		let s = shipping;
		let stores = s.items;
		let selectedDates = [];
		// debugger;
		if (storesModal.selectedStore.availableDeliveryWindows.length > 0) {
			storesModal.selectedStore.availableDeliveryWindows.forEach((dw, i) => {
				selectedDates.push(new Date(dw.startDateUtc));
			});
		}

		let store = storesModal.selectedStore;
		let storeAddress = null;
		if (store) {
			storeAddress = {
				name: store.pickupStoreInfo.friendlyName,
				addressLine1: store.pickupStoreInfo.address.street,
				addressLine2: store.pickupStoreInfo.address.number,
				department: store.pickupStoreInfo.address.state,
				province: store.pickupStoreInfo.address.city,
				district: store.pickupStoreInfo.address.neighborhood,
				postalCode: store.pickupStoreInfo.address.postalCode,
			}
		}

		stores.forEach((el, i) => {
			if (el.store == ss.store) {
				el = ss;
				el.selectedSla = storesModal.selectedStore;
				el.selectedDates = selectedDates;
				el.selectedDate = null;
				el.scheadule = [];
				el.selectedDeliveryDate = null;
				el.selectedDeliveryWindow = null;
				el.selectedDeliverySla = null;
				el.selectedDeliveryScheadule = null;
				el.deliveryScheadule = [];
				el.address = storeAddress
			}
		})

		ss.selectedSla = storesModal.selectedStore;
		s.items = stores;
		this.setState({ selectedStore: ss, shipping: s });

		$("#storesModal").modal("hide");
	}

	getCurrentLocation = () => {
		navigator.geolocation.getCurrentPosition((data) => {
		})
	}

	formatPrice = (price) => {
		if (!price)
			return "XXXX";
		price = price.toString();

		let i = price.substring(0, price.length - 2);
		let d = price.substring(price.length - 2, price.length);

		return `S/ ${i}.${d}`;
	}

	removeCartItem = (e, id) => {
		const { get, cartItemDelete } = this.props;
		cartItemDelete(get.draft.draftId, id);
	}

	getStoreShippingData = (storeId) => {
		const { get } = this.props;

		let externalCart = get.draft.externalCart.map((el, i) => {
			el.shippingData = JSON.parse(el.externalJsonCart);
			return el;
		})

		let itemSD = externalCart.filter((store, idx) => {
			return store.externalSellerId == storeId;
		})

		if (itemSD && itemSD.length > 0) {
			return itemSD[0];
		}
		return null;
	}

	mergeItems = () => {
		const { get } = this.props;

		// let sItems = get.draft.shippingData.items;
		let externalCart = get.draft.externalCart.map((el, i) => {
			el.shippingData = JSON.parse(el.externalJsonCart);
			return el;
		})
		let sItems = [];

		if (externalCart) {
			externalCart.forEach((el, i) => {
				el.shippingData.items.forEach((el, i) => {
					sItems.push(el);
				})
			})
		}

		let stores = {};
		let storesArray = [];
		let gItems = get.draft.items;
		// let lInfo = get.draft.shippingData.shippingData ? get.draft.shippingData.shippingData.logisticsInfo : get.draft.shippingData.logisticsInfo;
		// debugger;
		sItems.forEach((s, i) => {
			var itemSD = externalCart.filter((store, idx) => {
				return s.seller == store.externalSellerId;
			})

			if (itemSD && itemSD.length > 0) {
				itemSD = itemSD[0];
			}

			let lInfo = itemSD.shippingData ? itemSD.shippingData.logisticsInfo : itemSD.logisticsInfo;

			gItems.forEach((g, j) => {
				if (s.id === g.productId) {
					s.productInfo = g;
				}
			});

			lInfo.forEach((l, k) => {
				if (s.requestIndex === l.itemIndex) {
					s.logisticInfo = l;
				}
			})
		})

		sItems.forEach((el, i) => {
			let key = el.productInfo.sellerName.replace(/ /g, "");
			if (key === "")
				key = el.seller;

			if (stores[key]) {
				stores[key].products.push(el);
			} else {
				stores[key] = {
					products: [el]
				}
			}
		});

		for (let store in stores) {
			let shippingMethods = [];
			stores[store].products.forEach((el, i) => {
				if (i == 0) {
					shippingMethods = el.logisticInfo.deliveryChannels;
				} else {
					if (shippingMethods.length > el.logisticInfo.deliveryChannels.length) {
						shippingMethods = el.logisticInfo.deliveryChannels;
					}
				}
			})
			
			var sellerName = stores[store].products[0].productInfo.sellerName;

			storesArray.push({
				storeName: (sellerName && sellerName.length > 0) ? sellerName : stores[store].products[0].storeInfo.name,
				store: (stores[store].products[0].seller !== stores[store].products[0].storeInfo.name) ? stores[store].products[0].seller : stores[store].products[0].storeInfo.name,
				products: stores[store].products,
				shippingMethods: shippingMethods,
				shippingMethod: shippingMethods.length > 0 ? shippingMethods[0].id : ''
			});
		}

		return storesArray;
	}

	onChangeShippingMethod = (event, item) => {
		let shipping = this.state.shipping;
		let value = event.target.value;
		let items = shipping.items;

		items.forEach((el, i) => {
			if (el.store === item.store)
				el.shippingMethod = value
		})

		shipping.items = items;

		this.setState({ shipping: shipping });
	}

	renderShippingProducts = () => {
		const { get } = this.props;
		const { shipping } = this.state;

		let items = shipping.items;

		items.forEach((el, i) => {
			let products = el.products;
			products.forEach((p, j) => {
				let externalCart = get.draft.externalCart;
				externalCart.forEach((c, k) => {
					if (el.store === c.externalSellerId) {
						let cart = JSON.parse(c.externalJsonCart);
						let lInfo = cart.logisticsInfo;
						el._logisticInfo = lInfo;
						lInfo.forEach((item, l) => {
							if (p.requestIndex === item.itemIndex) {
								let pickupSlas = [];
								let deliverySlas = [];
								if (item.slas && item.slas.length > 0) {
									pickupSlas = item.slas.filter((_el, i) => { return _el.deliveryChannel === "pickup-in-point" });
									deliverySlas = item.slas.filter((_el, i) => { return _el.deliveryChannel === "delivery" });

									el.availablePickup = pickupSlas.length > 0;
									el.availableDelivery = deliverySlas.length > 0;
									el.shippindMethodsAvailability = {
										"pickup-in-point": pickupSlas.length > 0,
										"delivery": deliverySlas.length > 0
									}

									if (!el.shippingMethod || el.shippingMethod === "") {
										if (el.availableDelivery) {
											el.shippingMethod = "delivery"
										} else {
											el.shippingMethod = "pickup-in-point"
										}
									}
								} else {
									el.shippindMethodsAvailability = {
										"pickup-in-point": false,
										"delivery": false
									}
									el.shippingMethod = "delivery"
								}
								if (!item.slas || item.slas.length === 0) {

									p.outOfStock = true;
								}
							}
						})
					}
				})
			})
		})

		let shippingProducts = "";
		console.log(items);
		if (items && items.length > 0) {
			shippingProducts = (
				<div className="stores">
					{
						items.map((item, i) => {
							return (
								<div className="card mb-3" key={`${item.store}-${i}`}>
									<div className="card-header">
										<div className="store-info">
											<div className="form-group d-flex mb-0">
												{/* <div className="store-logo">
													<img src={this.setHttps(item.products[0].productInfo.sellerLogo)} alt="" />
												</div> */}
												{
													item.shippingMethods.map((sm, j) => {
														return (
															<div key={sm.id} className="shipping-methods">
																<div className="form-check form-check-inline">
																	<input className="form-check-input"
																		type="radio"
																		name={'shippingMethod' + item.store + i}
																		id={'shippingMethodDelivery' + item.store + i + sm.id}
																		value={sm.id}
																		disabled={!item.shippindMethodsAvailability[sm.id]}
																		checked={item.shippingMethod == sm.id}
																		onChange={(e) => { this.onChangeShippingMethod(e, item) }}
																	/>
																	<label className="form-check-label" htmlFor={'shippingMethodDelivery' + item.store + i + sm.id}>{this.getShippingName(sm.id)}</label>
																</div>
															</div>
														)
													})
												}
											</div>
										</div>
									</div>
									<div className="collapse show" id={'collapse-' + item.store}>
										<div className="card-body">
											<div className="row">
												<div className="col-12 col-md-6">
													{
														this.renderStoreProducts(item)
													}
												</div>
												<div className="col-12 col-md-6">
													{item.shippingMethod == 'delivery' &&
														this.renderProductDelivery(item)
													}

													{item.shippingMethod == 'pickup-in-point' && (
														this.renderProductPickup(item)
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			)
		}

		return (<React.Fragment>{shippingProducts}</React.Fragment>);
	}

	renderStoreProductsDetails = (items) => {
		return items.map((s, i) => {
			if (s.store == "1") {
				let sellers = [];
				let products = s.products;
				products.forEach(p => {
					if (!(sellers.some(s => s.storeName == p.productInfo.sellerName))) {
						var _store = {};
						for (let prop in s) {
							_store[prop] = s[prop];
						}
						_store.storeName = p.productInfo.sellerName;
						sellers.push(_store);
					}
				});

				return sellers.map((store, i) => {
					return (
						<React.Fragment key={'store-' + i}>
							<h4 className="mb- main-text d-none">
								{store.storeName} -
								<small>{this.getShippingName(store.shippingMethod)}</small>
							</h4>
							<div className="checkout__step__summary__info mb-3">
								<div className={store.shippingMethod === 'delivery' ? 'checkout__step__summary__info__icon delivery' : 'checkout__step__summary__info__icon address'}>
								</div>
								<div className="checkout__step__summary__info__content">
									<h5 className="main-text">{store.storeName} - <small>{this.getShippingName(store.shippingMethod)}</small></h5>
									{(store.shippingMethod === 'delivery' && store.selectedDeliverySla) && (
										<React.Fragment>
											<p>Direcci&oacute;n de entrega: {store.address.addressLine}{store.address.addressNumber != '' ? ', ' + store.address.addressNumber : ''}</p>
											{store.selectedDeliveryDate &&
												<p>Fecha de entrega: {this.formatDate(store.selectedDeliveryDate.toString())}</p>
											}
											{!store.selectedDeliveryDate &&
												<p>Entrega en {this.formatEstimate(store.selectedDeliverySla.shippingEstimate)}</p>
											}
										</React.Fragment>
									)}
									{store.shippingMethod === 'pickup-in-point' && (
										<React.Fragment>
											<p>Direcci&oacute;n de retiro: {store.selectedSla.pickupStoreInfo.address.street} {store.selectedSla.pickupStoreInfo.address.number}, {store.selectedSla.pickupStoreInfo.address.state}, {store.selectedSla.pickupStoreInfo.address.city}, {store.selectedSla.pickupStoreInfo.address.neighborhood}</p>
											{
												store.selectedSla.availableDeliveryWindows.length === 0 &&
												(
													<React.Fragment>
														<p>Entrega en  {this.formatEstimate(store.selectedSla.shippingEstimate)}</p>
													</React.Fragment>
												)
											}
											{
												store.selectedSla.availableDeliveryWindows.length > 0 &&
												(
													<React.Fragment>
														<p>Entrega en  {store.selectedDate.toString()}</p>
													</React.Fragment>
												)
											}
										</React.Fragment>
									)}
								</div>
							</div>
						</React.Fragment>
					)
				})
			} else {
				let store = s;
				let storeName = store.store;
				return (
					<React.Fragment key={'store-' + i}>
						<h4 className="mb- main-text d-none">
							<span className="text-capitalize">{storeName}</span> -
							<small>{this.getShippingName(store.shippingMethod)}</small>
						</h4>
						<div className="checkout__step__summary__info mb-3">
							<div className={store.shippingMethod === 'delivery' ? 'checkout__step__summary__info__icon delivery' : 'checkout__step__summary__info__icon address'}>
							</div>
							<div className="checkout__step__summary__info__content">
								<h5 className="main-text"><span className="text-capitalize">{storeName}</span> - <small>{this.getShippingName(store.shippingMethod)}</small></h5>
								{(store.shippingMethod === 'delivery' && store.selectedDeliverySla) && (
									<React.Fragment>
										<p>Direcci&oacute;n de entrega: {store.address.addressLine}{store.address.addressNumber != '' ? ', ' + store.address.addressNumber : ''}</p>
										{store.selectedDeliveryDate &&
											<p>Fecha de entrega: {this.formatDate(store.selectedDeliveryDate.toString())}</p>
										}
										{!store.selectedDeliveryDate &&
											<p>Entrega en {this.formatEstimate(store.selectedDeliverySla.shippingEstimate)}</p>
										}
									</React.Fragment>
								)}
								{store.shippingMethod === 'pickup-in-point' && (
									<React.Fragment>
										<p>Direcci&oacute;n de retiro: {store.selectedSla.pickupStoreInfo.address.street} {store.selectedSla.pickupStoreInfo.address.number}, {store.selectedSla.pickupStoreInfo.address.state}, {store.selectedSla.pickupStoreInfo.address.city}, {store.selectedSla.pickupStoreInfo.address.neighborhood}</p>
										{
											store.selectedSla.availableDeliveryWindows.length === 0 &&
											(
												<React.Fragment>
													<p>Entrega en  {this.formatEstimate(store.selectedSla.shippingEstimate)}</p>
												</React.Fragment>
											)
										}
										{
											store.selectedSla.availableDeliveryWindows.length > 0 &&
											(
												<React.Fragment>
													<p>Entrega en  {store.selectedDate.toString()}</p>
												</React.Fragment>
											)
										}
									</React.Fragment>
								)}
							</div>
						</div>
					</React.Fragment>
				)
			}
		})
	}

	renderStoreProducts = (item) => {
		// debugger;
		if (item.store == "1") {
			let sellers = [];
			let products = item.products;
			products.forEach(p => {
				if (!(sellers.some(s => s.sellerId == p.productInfo.sellerId)))
					sellers.push({
						sellerId: p.productInfo.sellerId,
						sellerName: p.productInfo.sellerName,
						sellerLogo: p.productInfo.sellerLogo,
						products: []
					});
			});

			if (sellers.length > 0) {
				sellers.forEach(s => {
					products.forEach(p => {
						if (p.productInfo.sellerId == s.sellerId) {
							s.products.push(p)
						}
					});
				});
			}
			return (
				sellers.map((s, i) => {
					return (
						<div className="mb-3" key={s.sellerId}>
							<div className="store-logo mb-2">
								<img src={s.sellerLogo} alt={s.sellerName} />
							</div>
							{
								s.products.map((p, k) => {
									return (
										<div key={p.id} className="store-products">
											<div className="product-img">
												<img src={this.setHttps(p.productInfo.productImage)} title={p.productInfo.productName} alt="" />
											</div>
											<div className="product-info">
												<b>{p.productInfo.productName}</b>
												<small>S/ {(p.sellingPrice / 100).toFixed(2)}</small>
												{(p.outOfStock) &&
													<React.Fragment>
														<span className="out-stock mt-2 d-block">Fuera de Stock. <a role="button" onClick={(e) => { this.removeCartItem(e, p.id) }}>Quitar del carrito</a></span>
													</React.Fragment>
												}
											</div>
										</div>
									)
								})
							}
						</div>
					)
				})
			)
		}

		return (

			<React.Fragment>
				<div className="store-logo mb-2">
					<img src={"https://mercury.vteximg.com.br/arquivos/" + item.store + ".png"} alt={item.products[0].productInfo.sellerName} />
				</div>
				{
					item.products.map((p, k) => {
						return (
							<div key={p.id} className="store-products">
								<div className="product-img">
									<img src={this.setHttps(p.productInfo.productImage)} title={p.productInfo.productName} alt="" />
								</div>
								<div className="product-info">
									<b>{p.productInfo.productName}</b>
									<small>S/ {(p.sellingPrice / 100).toFixed(2)}</small>
									{(p.outOfStock) &&
										<React.Fragment>
											<span className="out-stock mt-2 d-block">Fuera de Stock. <a role="button" onClick={(e) => { this.removeCartItem(e, p.id) }} alt="Quitar del carrito">Quitar del carrito</a></span>
										</React.Fragment>
									}
								</div>
							</div>
						)
					})
				}
			</React.Fragment>
		)
	}

	saveShippingInfo = async (e) => {
		const { shipping, user, ubigeos } = this.state;
		const { get, shippingPut } = this.props;

		let stores = shipping.items;
		let addresses = [];
		let items = [];
		let department = "";
		let province = "";
		let district = "";
		let isValid = true;
		let message = ""

		this.saveDataLayerEvent({
			'event': 'virtualEvent',
			'category': 'Checkout - Metodo de entrega',
			'action': 'Seleccionar boton',
			'label': 'Continuar - Tipo de envio'
		});

		stores.forEach((el, i) => {
			let datePicker = $("#collapse-" + el.store).find(".date-picker");
			let deliveryWindow = $("#collapse-" + el.store).find("[name=deliveryTime]");
			if (datePicker.length > 0) {
				if (el.shippingMethod === "delivery") {
					if (!el.selectedDeliveryDate) {
						datePicker.addClass("not-valid");
						datePicker.find("input").addClass("form-control is-invalid");
						isValid = false;
					}
				} else {
					if (!el.selectedDate) {
						datePicker.addClass("not-valid");
						datePicker.find("input").addClass("form-control is-invalid");
						isValid = false;
					}
				}
			}

			if (deliveryWindow.length > 0) {
				if (!deliveryWindow.val() || deliveryWindow.val() === "0") {
					deliveryWindow.closest('.form-control').addClass("not-valid");
					deliveryWindow.addClass("form-control is-invalid");
					isValid = false;
				}
				// if (el.shippingMethod === "delivery") {
				// 	if (!el.deliveryDeliveryWindow || el.deliveryDeliveryWindow === "") {
				// 		deliveryWindow.closest('.form-control').addClass("not-valid");
				// 		deliveryWindow.addClass("form-control is-invalid");
				// 		isValid = false;
				// 	}
				// } else {

				// }
			}

			if (!el.selectedSla && el.shippingMethod === "pickup-in-point") {
				message = "Por favor seleccione una tienda, pare recoger el producto.";
				isValid = false;
			}
		});

		if ($(".out-stock").length > 0) {
			message = "Por favor retire los productos sin stock de su carrito";
			isValid = false;
		}

		if (!isValid) {
			this.showToastr("", message);
			return;
		}

		stores.forEach((el, i) => {
			el.products.forEach((p, j) => {
				let item = null;
				let address = null;
				let addressType = "";

				if (p.logisticInfo.slas.length > 0) {
					if (el.shippingMethod === "delivery") {
						let sla = p.logisticInfo.slas.filter((s, k) => {
							return s.deliveryChannel === "delivery";
						});

						sla = sla.length > 0 ? sla[0] : null;
						el.selectedDeliverySla = sla;

						item = {
							"addressId": `address-residential-001`,
							"productId": p.id,
							"itemIndex": p.requestIndex,
							"selectedDeliveryChannel": el.shippingMethod,
							"selectedSLAId": el.selectedDeliverySla.id,
							"price": el.selectedDeliverySla.price / 100,
							"deliveryWindow": el.deliveryDeliveryWindow,
							"sellerId": el.store
						}

						address = {
							"addressId": `address-residential-001`,
							"addressType": "residential",
							"firstName": user.data.firstName,
							"lastName": user.data.lastName,
							"phone": user.data.phone,
							"cellPhone": user.data.phone,
							"addressLine": shipping.data.addressLine,
							"addressNumber": shipping.data.addressNumber,
							"reference": shipping.data.reference,
							"countryISO": shipping.data.countryISO,
							"postalCode": shipping.data.postalCode,
							"department": shipping.data.department,
							"province": shipping.data.province,
							"district": shipping.data.district
						}
					} else if (el.shippingMethod === "pickup-in-point") {
						item = {
							"addressId": `addressw-${i}-${j}`,
							"productId": p.id,
							"itemIndex": p.requestIndex,
							"selectedDeliveryChannel": el.shippingMethod,
							"selectedSLAId": el.selectedSla.id,
							"price": el.selectedSla.price / 100,
							"deliveryWindow": el.selectedDeliveryWindow,
							"sellerId": el.store
						}

						address = {
							"addressId": `addressw-${i}-${j}`,
							"addressType": "search",
							"firstName": user.data.firstName,
							"lastName": user.data.lastName,
							"phone": user.data.phone,
							"cellPhone": user.data.phone,
							"addressLine": el.selectedSla.pickupStoreInfo.address.street,
							"addressNumber": el.selectedSla.pickupStoreInfo.address.number,
							"reference": el.selectedSla.pickupStoreInfo.address.reference,
							"countryISO": el.selectedSla.pickupStoreInfo.address.country,
							"postalCode": el.selectedSla.pickupStoreInfo.address.postalCode,
							"department": el.selectedSla.pickupStoreInfo.address.state,
							"province": el.selectedSla.pickupStoreInfo.address.city,
							"district": el.selectedSla.pickupStoreInfo.address.neighborhood,
							"geoCoordinates": el.selectedSla.pickupStoreInfo.address.geoCoordinates,
							"address": el.address
						}
					}

					addresses.push(address);
					items.push(item);
				}
			})
		})

		let model = {
			postalCode: shipping.data.postalCode,
			items: items,
			address: addresses
		}

		await shippingPut(get.draft.draftId, model, 2)

		shipping.editing = false;
		shipping.isValid = true;
		this.setState({ shipping: shipping });

		this.saveDataLayerEvent({
			'event': 'virtualPage',
			'pagePath': '/Checkout/IBK/3/Metodo-de-pago',
			'pageName': 'Checkout - Interbank - Método de pago'
		});
		this.saveDataLayerStepEvent(3, 'Metodo de pago');
	}

	renderProductDelivery = (item) => {
		const { get } = this.props;
		let slas = [];
		let sla = [];
		let deliveryWindows = [];
		if (get.draft) {
			var itemSD = this.getStoreShippingData(item.store);

			if (!itemSD)
				return;

			let li = itemSD.shippingData.shippingData ? itemSD.shippingData.shippingData.logisticsInfo : itemSD.shippingData.logisticsInfo;
			li.forEach((el, i) => {
				item.products.forEach((p, j) => {
					if (el.itemIndex == p.requestIndex) {
						slas.push(el);
					}
				})
			})
		}

		sla = slas[0].slas.filter((s, i) => {
			return s.deliveryChannel == "delivery";
		});

		if (sla.length > 0) {
			sla = sla[0];
			sla.availableDeliveryWindows.forEach((dw, i) => {
				deliveryWindows.push(new Date(dw.startDateUtc));
			})
		}

		let template = (
			<React.Fragment>
				<div className="card address-card">
					<div className="card-body">
						<p className="main-text mb-0">
							<b>{item.address.addressLine}, {item.address.addressNumber}</b>
						</p>
						<p >
							<b>Referencia: </b>
							{item.address.reference}
						</p>
						<a className="btn-edit" onClick={this.editStoresAddress}>Editar direcci&oacute;n</a>
					</div>
				</div>

				{(deliveryWindows.length > 0) && (
					<div className="row justify-content-between">
						<div className="col-md-12">
							<div className="form-group date-picker">
								<DatePicker
									id={'datepicker-' + item.store}
									portalId="root-portal"
									className="store-input"
									selected={typeof item.selectedDeliveryDate === "string" ? new Date(item.selectedDeliveryDate) : item.selectedDeliveryDate}
									placeholderText={'Seleccionar fecha de entrega'}
									locale="es-ES"
									includeDates={deliveryWindows}
									onChange={(e) => { this.handleChangeDeliveryDate(e, item, sla.availableDeliveryWindows) }}
								/>
								<label className="form-icon p-0" htmlFor={'datepicker-' + item.store} role="button">
									<i className="far fa-calendar-alt  main-text"></i>
								</label>
							</div>
						</div>
						<div className="col-md-12">
							{(item.deliveryScheadule && item.deliveryScheadule.length > 0) && (
								<React.Fragment>
									<div className="form-group">
										<label>Rango Horario*</label>
										<select className="form-control store-input" name="deliveryTime" value={item.deliveryDeliveryWindowId} onChange={(e) => this.handleChangeDeliveryTime(e, item)}>
											<option value="0">Seleccionar</option>
											{item.deliveryScheadule.map((hour) => {
												return (
													<option key={hour.id} value={hour.id}>{hour.label}</option>
												)
											})}
										</select>
									</div>
								</React.Fragment>
							)}
						</div>
					</div>
				)}
				{(deliveryWindows.length == 0 && sla.shippingEstimate) && (
					<div className="card shipping-days">
						<div className="card-body">
							<i className="far fa-dot-circle mr-2 text-main"></i>
							{this.formatEstimate(sla.shippingEstimate)}
						</div>
					</div>
				)}
			</React.Fragment>
		);

		return <React.Fragment>{template}</React.Fragment>
	}

	renderProductPickup = (item) => {
		let template = "";
		if (item.selectedSla) {
			template = (
				<React.Fragment>
					<div className="pickup-store">
						<div className="card">
							<div className="card-body">
								<h4>{item.selectedSla.pickupStoreInfo.friendlyName}</h4>
								<p className="mb-0">{item.selectedSla.pickupStoreInfo.address.street} {item.selectedSla.pickupStoreInfo.address.number} {item.selectedSla.pickupStoreInfo.address.complement}</p>
								<p className="mb-0">{item.selectedSla.pickupStoreInfo.address.state}, {item.selectedSla.pickupStoreInfo.address.city}, {item.selectedSla.pickupStoreInfo.address.neighborhood}</p>
								<a className="btn-edit" onClick={(e) => { this.showStoresList(e, item) }}>Cambiar tienda</a>
							</div>
						</div>

						{item.selectedDates.length > 0 &&
							<div className="row justify-content-between">
								<div className="col-md-12">
									<div className="form-group date-picker">
										<DatePicker
											locale="es-US"
											selected={item.selectedDate ? new Date(item.selectedDate) : null}
											includeDates={item.selectedDates}
											placeholderText="Seleccionar fecha de recojo"
											onChange={(e) => { this.handleChangePickupDate(e, item) }}
										/>
										<i className="far fa-calendar-alt form-icon main-text"></i>
									</div>
								</div>
								<div className="col-md-12">
									{item.scheadule.length > 0 && (
										<React.Fragment>
											<div className="form-group">
												<label>Rango Horario*</label>
												<select className="form-control" name="deliveryTime" value={item.selectedScheadule} onChange={(e) => this.handleChangePickupTime(e, item)}>
													<option value="0">Seleccionar</option>
													{item.scheadule.map((hour) => {
														return (
															<option key={hour.id} value={hour.id}>{hour.label}</option>
														)
													})}
												</select>
											</div>
										</React.Fragment>
									)}
								</div>
							</div>
						}
					</div>
				</React.Fragment>
			)
		} else {
			template = (
				<React.Fragment>
					<div className="d-none">
						<div className="address-locator">
							<h2 className="text-center">Vamos sugerir un punto de recogida cerca de usted</h2>
							<h3 className="text-center">Para esto, necesitamos saber su ubicaci&oacute;n</h3>
						</div>
						<div className="map-marker-icon">
							<svg fill="none" height="106%" version="1.1" viewBox="0 0 212 212" width="106%" xmlns="http://www.w3.org/2000/svg">
								<g stroke="#FFFFFF" strokeWidth="3">
									<rect height="146.594378" rx="3" transform="translate(-18.622687, 76.959642) rotate(45.000000) translate(18.622687, -76.959642) " width="146.594378" x="-91.9198759" y="3.66245343"></rect>
									<rect height="146.594378" rx="3" transform="translate(154.068076, -49.144775) rotate(45.000000) translate(-154.068076, 49.144775) " width="146.594378" x="80.7708872" y="-122.441964"></rect>
									<rect height="146.594378" rx="3" transform="translate(194.228718, 170.132333) rotate(45.000000) translate(-194.228718, -170.132333) " width="146.594378" x="120.93153" y="96.8351442"></rect>
									<rect height="146.594378" rx="3" transform="translate(47.240767, 263.305024) rotate(45.000000) translate(-47.240767, -263.305024) " width="146.594378" x="-26.0564221" y="190.007835"></rect>
									<rect height="146.594378" rx="3" transform="translate(225.521891, 86.630325) rotate(45.000000) translate(-225.521891, -86.630325) " width="146.594378" x="152.224702" y="13.3331362"></rect>
									<rect height="146.594378" rx="3" transform="translate(-1.755217, 3.064060) rotate(45.000000) translate(1.755217, -3.064060) " width="146.594378" x="-75.052406" y="-70.2331289"></rect>
								</g>
								<path d="M33,0 C14.7792857,0 0,15.048 0,33.6 C0,58.8 33,96 33,96 C33,96 66,58.8 66,33.6 C66,15.048 51.2207143,0 33,0 Z M33,45.6 C26.4942857,45.6 21.2142857,40.224 21.2142857,33.6 C21.2142857,26.976 26.4942857,21.6 33,21.6 C39.5057143,21.6 44.7857143,26.976 44.7857143,33.6 C44.7857143,40.224 39.5057143,45.6 33,45.6 Z" fill="#FFFFFF" id="Shape" transform="translate(66.666667, 52.208835)"></path>
							</svg>
						</div>
					</div>
					<div className="text-center address-locator-actions">
						<button className="btn btn-main" type="button" onClick={(e) => { this.openModal('map', item) }}>Ver todas las tiendas</button>
					</div>
				</React.Fragment>
			)
		}

		return <React.Fragment>{template}</React.Fragment>;
	}

	unavailableProducts = () => {
		const { get } = this.props;

		if (get && get.draft && get.draft.shippingData && get.draft.shippingData.items && get.draft.shippingData.items.length > 0) {
			let items = get.draft.shippingData.items;
			items = items.filter((el, i) => {
				return el.availability == "cannotBeDelivered";
			})

			if (items.length > 0)
				return true;
		}

		return false;
	}

	setDefaultAddress = () => {
		const { shipping, billing } = this.state;

		let hasErrors = this.verifyErros("#shippingForm");
		let isValid = this.verifyObject(shipping.data);

		if (hasErrors || !isValid) {
			this.showToastr("", "");
			return;
		}

		this.saveDataLayerEvent({
			'event': 'virtualEvent',
			'category': 'Checkout - Metodo de entrega',
			'action': 'Seleccionar boton',
			'label': 'Continuar - Direccion de envio'
		});

		let s = shipping;
		let b = billing;
		let items = this.mergeItems();

		b.data = {
			department: shipping.data.department,
			province: shipping.data.province,
			district: shipping.data.district,
			addressLine: shipping.data.addressLine,
			addressNumber: shipping.data.addressNumber,
			postalCode: shipping.data.postalCode
		}

		s.editingDefault = false;
		s.items = items;

		s.items.forEach((el, i) => {
			el.address = {
				addressType: "residential",
				addressId: "123",
				department: shipping.data.department,
				province: shipping.data.productId,
				district: shipping.data.district,
				addressLine: shipping.data.addressLine,
				addressNumber: shipping.data.addressNumber,
				reference: shipping.data.reference,
				countryISO: "PER",
				postalCode: "150101",
			};
			el.selectedDates = [];
			el.selectedDate = null;
			el.scheadule = [];
			el.selectedDeliveryDate = null;
			el.selectedDeliveryWindow = null;
			el.selectedDeliverySla = null;
			el.selectedDeliveryScheadule = null;
			el.deliveryScheadule = [];
		})
		console.log("Shipping", s);
		this.setState({ shipping: s, billing: b });
	}

	editStoresAddress = (e) => {
		const { shipping } = this.state;
		let s = shipping;

		s.editingDefault = true;

		this.setState({ shipping: s });
	}

	getShippingName = (key) => {
		let shippingNames = {
			'delivery': 'Entrega a domicilio',
			'pickup-in-point': 'Retiro en tienda'
		}

		return shippingNames[key];
	}

	ibkAuthenticate = async () => {
		const { getMiles } = this.props;
		const { miles } = this.state;
		let customerId = window.localStorage.getItem("__customerId__");
		let draft = JSON.parse(window.localStorage.getItem("__get__"));

		if (!customerId)
			return;

		let model = {
			customerId: customerId,
			checkOutId: draft.draft.draftId
		}

		await getMiles(model);
		const { ibkMiles, get } = this.props;
		const totalOrder = get.draft.total;

		if (ibkMiles.data) {
			let m = miles;
			let selectedMiles = ibkMiles.data.miles;
			const exchange = ibkMiles.data.miles / ibkMiles.data.exchange;

			if (selectedMiles > 0)
				await this.getInterbankDiscount()

			if (ibkMiles.data.exchange > totalOrder) {
				selectedMiles = this.calculateSelectedMilesAmount();
			}
			m.data = {
				total: ibkMiles.data.miles,
				exchange: ibkMiles.data.exchange,
				selected: Math.ceil(selectedMiles),
				change: exchange
			};
			m.loaded = true;
			this.setState({ miles: m });
		}
	}

	calculateSelectedMilesAmount = () => {
		const { ibkMiles, get } = this.props;
		const exchange = ibkMiles.data.miles / ibkMiles.data.exchange;
		const totalOrder = get.draft.total;

		let miles = get.draft.total * exchange;
		let milesDiscount = miles / exchange;

		if (milesDiscount < totalOrder) {
			miles += 1;
		}

		return miles;
	}

	calculateMilesDiscount = () => {
		const { miles } = this.state;
		const { get } = this.props;

		let discount = miles.data.selected / miles.data.change;

		if (discount > get.draft.total) {
			discount = get.draft.total;
		}

		return discount.toFixed(2).toLocaleString('en')
	}

	changeMilesAmount = (e, op) => {
		const { miles } = this.state;
		const { get } = this.props;
		const change = miles.data.change;

		let amount = op * 100;
		let selected = parseFloat(miles.data.selected) + amount;

		if (selected > 0) {
			if (selected > miles.data.total) {
				selected = miles.data.total;
			}

			if ((selected / change) > get.draft.total) {
				selected = get.draft.total * change + 1;
			}
		} else {
			selected = 0;
		}

		let m = miles;
		m.data.selected = Math.round(selected);

		this.setState({ miles: m });
	}

	validateIbkAuth = () => {
		const { get, ibkAuth, ibkMiles, getInstallments } = this.props;
		const { payment } = this.state;

		if (payment.data.type == 2 && !ibkMiles.data) {
			if (!ibkAuth.isAuthenticated) {
				// $("#milesLoginModal").modal("show");
				$("#validateMilesModal").modal("show");
			}
		} else if (payment.data.type == 3) {
			let checkoutId = get.draft.draftId;
			// getInstallments(checkoutId);
		}
	}

	closeValidateMilesModal = () => {
		const { payment } = this.state;
		let p = payment;
		p.data.type = 1;
		this.setState({ payment: p });
		$("#validateMilesModal").modal("hide");
	}

	calculateExchange = (e) => {
		const { miles } = this.state;
		let m = miles;

		m.data.exchange = miles.data.selected / miles.data.change;
		this.setState({ miles: m });
	}

	redirectInterbankLogin = (e) => {
		const { get, match } = this.props;
		const { miles } = this.state;
		let state = this.state;
		let customer = window.localStorage.getItem("__customerId__")
		// if (!customer) {
		window.localStorage.setItem("__state__", JSON.stringify(state));
		window.localStorage.setItem("__get__", JSON.stringify(this.props.get));
		window.localStorage.setItem("__checkoutId__", get.draft.draftId);
		window.localStorage.setItem("__orderFormId__", match.params.id);
		window.location.href = `${KEYS.interbankLoginPage}&redirect_uri=${KEYS.redirectUri}`;
		// }
	}

	onChangePolicyCheck = (e) => {
		const { policies } = this.state;
		const name = e.target.getAttribute("name");

		let p = policies;

		p[name] = !p[name];
		this.setState({ policies: p })
	}

	onCheckCopyShippingInfo = (e) => {
		const { useShippingAddress } = this.state;
		const name = e.target.getAttribute("name");
		let u = !useShippingAddress;
		this.setState({ useShippingAddress: u })
	}

	verifyErros = (selector) => {
		const { payment } = this.state;
		let container = $(selector);
		if (container.find(".is-invalid").length > 0)
			return true;
		return false;
	}

	verifyObject = (obj) => {
		let isValid = true;
		for (let prop in obj) {
			if (prop !== "cardNumber") {
				let isRequired = $(`[name=${prop}]`).attr("required") ? true : false;
				if (isRequired && (obj[prop] === "" || obj[prop] == "0")) {
					isValid = false;
					$(`[name=${prop}]`).addClass("is-invalid");
				}
			} else {
				let input = $(`[name=${prop}]`).closest(".card-input");
				if (!input.hasClass("valid")) {
					isValid = false;
					$(`[name=${prop}]`).addClass("is-invalid");
				}
			}
		}

		return isValid;
	}

	checkData = () => {
		const { user, shipping, orderType, billing, policies, payment } = this.state;
		const { get } = this.props;

		let userComplete = $("#userSummary");
		let shippingComplete = $("#shippingSummary");
		let errors = $(".is-invalid").length;
		let valid = true;

		if ($(".out-stock").length > 0) {
			valid = false;
		}

		if (userComplete.length === 0) {
			valid = false;
		}

		if (shippingComplete.length === 0) {
			valid = false;
		}

		if (errors > 0) {
			valid = false;
		}

		if (!policies.personalData || !policies.terms) {
			valid = false;
		}

		return !valid;
	}

	renderInstallmentsList = () => {
		const { get, getInstallments, installments } = this.props;
		const { payment } = this.state;

		if (!installments.data || installments.loading) {
			return (
				<div className="installments-list">
					<div className="card">
						<div className="card-header" id="installmentsListHeading">
							<h2 className="mb-0">
								<button className="btn btn-link btn-block text-left collapsed" disabled={true}>
									Total S/ {get.draft.total.toFixed(2)}
									{installments.loading &&
										<i className="loading-icon fas fa-spinner fa-pulse"></i>
									}
									{!installments.loading &&
										<i className="loading-icon fas fa-caret-down"></i>
									}
								</button>
							</h2>
						</div>
					</div>
				</div>
			);
		}

		//console.log(get.draft.total, payment);
		let selectedInstallments = payment.data.installments;
		let installmentsList = "";
		let installmentsText = "";
		let installmentsAmounts = installments.data;

		if ((selectedInstallments == 1 || selectedInstallments == 0) && get && get.draft) {
			installmentsText = "Total S/ " + get.draft.total.toFixed(2);
		} else {
			let installmentInfo = installmentsAmounts.filter((el, i) => {
				return selectedInstallments == el.installments;
			})
			if (installmentInfo.length > 0) {
				installmentsText = selectedInstallments + " cuotas de S/ " + installmentInfo[0].amount.toFixed(2);
			}
		}

		installmentsList = (
			<div className={payment.isIbkCard == 'valid' ? 'installments-list valid-ibk-card' : 'installments-list '}>
				<div className="card">
					<div className="card-header" id="installmentsListHeading">
						<h2 className="mb-0">
							<button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#installmentsList" aria-expanded="false" aria-controls="collapseOne" disabled={payment.isIbkCard != 'valid'}>
								{installmentsText}
								{payment.isIbkCard == 'valid' &&
									<i className="fas"></i>
								}
							</button>
						</h2>
					</div>

					<div id="installmentsList" className="collapse" aria-labelledby="installmentsListHeading">
						<ul className="list-group list-group-flush">
							<li className="list-group-item">
								<span>Cuotas</span>
								<span>Pago mensual</span>
							</li>
							{
								installmentsAmounts.map((el, i) => {
									return (
										<li className="list-group-item" key={'installment-' + i}>
											<a role="button" onClick={(e) => { this.setInstallments(el) }}>
												<span>{el.installments}</span>
												<span>S/ {el.amount.toFixed(2)}</span>
											</a>
										</li>
									)
								})
							}
						</ul>
					</div>
				</div>
			</div>
		);



		return <React.Fragment>{installmentsList}</React.Fragment>;
	}

	setInstallments = (item) => {
		const { payment } = this.state;
		let p = payment

		p.data.installments = item.installments;
		p.data.installmentAmount = item.amount;
		$(".installments-list .card").removeClass("error");
		$('#installmentsList').collapse('hide');

		this.setState({ payment: p });
	}

	selectSavedAddress = (address, index) => {
		address.selected = true;
		let shippingAddress = this.state.shippingAddress;
		let shipping = this.state.shipping;

		shippingAddress.list.forEach((el, i) => {
			if (index === i) {
				el = address;
			} else {
				el.selected = false;
			}
		});

		shipping.data.department = address.department;
		shipping.data.province = address.province;
		shipping.data.district = address.district;
		shipping.data.addressLine = address.addressLine;
		shipping.data.addressNumber = address.addressNumber;
		shipping.data.reference = address.reference;
		shipping.data.postalCode = address.stateCode;

		this.loadProvinces(null, 'shipping');
		this.loadDistricts(null, 'shipping');
		this.getAddress();

		this.setState({ shippingAddress: shippingAddress, shipping: shipping });
	}

	toggleAddress = (view) => {
		let shippingAddress = this.state.shippingAddress;
		let shipping = this.state.shipping;

		shippingAddress.view = view;

		if (view === "form") {
			shipping.data.department = "0";
			shipping.data.province = "0";
			shipping.data.district = "0";
			shipping.data.addressLine = "";
			shipping.data.addressNumber = "";
			shipping.data.reference = "";
		}

		this.setState({ shippingAddress: shippingAddress, shipping: shipping });
	}

	goToCart = () => {
		window.localStorage.removeItem("__state__");
		window.localStorage.removeItem("__get__");
		window.localStorage.removeItem("__customerId__");

		window.location.href = KEYS.siteUrl + "checkout/#/cart";
	}

	render() {
		const { post, get, userStatus, shippingStatus, start, shippingPut, ibkMiles, transaction } = this.props;
		const { user, shipping, payment, company, orderType, ubigeos, storesModal, billing, billingUbigeos, miles, auth, policies, useShippingAddress, shippingAddress } = this.state;
		const customerId = window.localStorage.getItem("__customerId__");
		const _get = JSON.parse(window.localStorage.getItem("__get__"));
		const enableBenefits = window.localStorage.getItem("__enableBenefits__");
		const MyMapComponent = withGoogleMap((props) =>
			<GoogleMap
				defaultZoom={15}
				defaultCenter={{ lat: storesModal.defaultLat, lng: storesModal.defaultLong }}
			>
				{props.isMarkerShown && <Marker position={{ lat: storesModal.defaultLat, lng: storesModal.defaultLong }} />}
			</GoogleMap>
		)

		return (
			<div className="App">
				{(start.loading && _get) &&
					<div className="callback-loader">
						<div className="text-center">
							<i className="fas fa-spinner fa-pulse main-text d-block fa-3x mb-4"></i>
							<h4 className="text-center">Cargando Checkout</h4>
						</div>
					</div>
				}
				<div id="header">
					<div className="bg-orange -fr"></div>
					<div className="center-wrap -tbl -ch">
						<div className="tb-row">
							<div className="tb-cell -c1">
								<a className="logo" href={process.env.REACT_APP_SITE_URL}>
									<img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/logo.png" alt="Shopstar Checkout" width="140" />
								</a>
							</div>
							<div className="tb-cell -c2">
								<div className="contact">Contáctanos (01) 289-1616 </div>

								<a href="mailto:hola@shopstar.com.pe">hola@shopstar.com.pe </a>
							</div>
						</div>
					</div>
					<div className="bg-gris -fr"></div>
				</div>
				<div className="checkout">
					<div className="container">
						{!get.draft &&
							<React.Fragment>
								<div className="row">
									<div className="col-md-12">
										<div className="checkout__step">
											<div className="row justify-content-center">
												<div className="col-md-8">
													<form onSubmit={this.handleContinueSubmit} noValidate id="continueForm">
														<div className="checkout__step__email-form">
															<h4 className="text-main text-center">¡Estás a un paso!</h4>
															<p className="text-center">Ingresa tu e-mail para continuar la compra</p>
															<div className={'form-group ' + (post.loading ? 'loading' : '')}>

																<input type="email" className="form-control" name="firstEmail" required placeholder="su@correo.com" onChange={this.handleEmailChange} />
																<button className="btn btn-main" type="submit" disabled={start.loading}>
																	CONTINUAR COMPRA
																	<i className="fas fa-spinner fa-pulse loading-icon"></i>
																</button>
															</div>
															<div className="card start-message">
																<div className="card-body text-center">
																	<b>Si ya estás registrado, tus datos se cargarán automáticamente</b>
																</div>
															</div>
														</div>
													</form>
												</div>
												<div className="w-100"></div>
												{/* <div className="col-md-5">
													<div className="checkout__step__dots">
														<h4 className="text-center">
															Guardamos su correo electr&oacute;nico de manera 100% segura para:
														</h4>
														<ul className="list-unstyled">
															<li>
																<i className="fas fa-circle"></i>
																Identificar su perfil.
															</li>
															<li>
																<i className="fas fa-circle"></i>
																Notificar sobre los estados de su compra.
															</li>
															<li>
																<i className="fas fa-circle"></i>
																Guardar el historial de compras.
															</li>
															<li>
																<i className="fas fa-circle"></i>
																Facilitar el proceso de compras.
															</li>
														</ul>
														<div className="icon-lock"></div>
													</div>
												</div> */}
											</div>
										</div>
									</div>
								</div>
							</React.Fragment>
						}
						{get.draft &&
							<React.Fragment>
								<div className="row">
									<div className="col">
										<h1 className="title title-icon">
											Finalizar Compra
											<a role="button" onClick={this.goToCart} className="title-link" alt="Regresar al carrito">Volver a carrito de compra</a>
										</h1>
									</div>
								</div>
								<div className="row">
									<div className="col-md-7">
										<div className="checkout__step">
											{userStatus.view == 'form' &&
												<React.Fragment>
													<div className="checkout__step__form">
														<form onSubmit={this.handleUserSubmit} noValidate id="userForm">
															<div className="card card-default">
																<div className="card-body">
																	<h3 className="main-text">Paso 1 - Datos Personales</h3>
																	<p className="">Solicitamos &uacute;nicamente la informaci&oacute;n esencial para la finalizaci&oacute;n de la compra.</p>
																	<div className="row">
																		<div className="col">
																			<div className="form-group">
																				<label >Correo</label>
																				<input type="text" className="form-control" required name="email" value={user.data.email} onChange={(e) => this.handleInputChange('user', e)} />
																				<small className="form-text text-danger d-none">Este campo es obligatorio.</small>
																			</div>
																		</div>
																		<div className="w-100"></div>
																		<div className="col">
																			<div className="form-group">
																				<label>Nombre</label>
																				<input type="text" className="form-control" required name="firstName" value={user.data.firstName} onChange={(e) => this.handleInputChange('user', e)} />
																			</div>
																		</div>
																		<div className="col">
																			<div className="form-group">
																				<label>Apellidos</label>
																				<input type="text" className="form-control" required name="lastName" value={user.data.lastName} onChange={(e) => this.handleInputChange('user', e)} />
																			</div>
																		</div>
																		<div className="w-100"></div>
																		<div className="col">
																			<div className="form-group">
																				<label>Documento de Identidad</label>
																				<input type="text" className="form-control" required name="identificationNumber" value={user.data.identificationNumber} onChange={(e) => this.handleInputChange('user', e)} />
																			</div>
																		</div>
																		<div className="col">
																			<div className="form-group">
																				<label>Tel&eacute;fono/M&oacute;vil</label>
																				<input type="text" className="form-control" required name="phone" value={user.data.phone} onChange={(e) => this.handleInputChange('user', e)} />
																			</div>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col">
																			<div className="form-group mt-4 text-right">
																				<button className="btn btn-main" type="submit" disabled={userStatus.loading}>
																					CONTINUAR
																					{userStatus.loading &&
																						<i className="fas fa-spinner fa-pulse loading-icon"></i>
																					}
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</form>
													</div>
												</React.Fragment>
											}

											{userStatus.view == 'summary' &&
												<React.Fragment>
													<div className="checkout__step__summary mt-3" id="userSummary">
														<div className="card">
															<div className="card-body">
																<h3 className="main-text">
																	Paso 1 - Datos Personales
																	<a role="button" onClick={this.handleEditUser}>Editar</a>
																</h3>
																<React.Fragment>
																	<div className="checkout__step__summary__info">
																		<div className="checkout__step__summary__info__icon user">
																		</div>
																		<div className="checkout__step__summary__info__content">
																			<h5 className="main-text">{get.draft.customerEmail}</h5>
																			<p>Nombre: {get.draft.customerFirstName + ' ' + get.draft.customerLastName}</p>
																			<p>Tel&eacute;fono: {get.draft.customerPhone}</p>
																		</div>
																	</div>
																</React.Fragment>
															</div>
														</div>
													</div>
												</React.Fragment>
											}
										</div>

										<div className="checkout__step">
											{(shipping.editing && userStatus.view == 'summary') &&
												<React.Fragment>
													<div className="checkout__step__form">
														<form onSubmit={this.handleShippingSubmit} noValidate id="shippingForm">
															<div className="card card-default">
																<div className="card-body">
																	<h3 className="main-text">Paso 2 - M&eacute;todo de Entrega</h3>

																	{shipping.editingDefault &&
																		<React.Fragment>
																			{shippingAddress.view === "list" &&
																				<React.Fragment>
																					{
																						<div className="address-list">
																							<div className="row">
																								{
																									shippingAddress.list.map((ad, i) => {
																										return (
																											<div className="col-md-6 col-12" key={`savedAddress` + i}>
																												<a role="button" onClick={() => { this.selectSavedAddress(ad, i); }}>
																													<div className="card address-list__item">
																														<div className={ad.selected ? `card-body selected` : `card-body`}>
																															<p>{ad.addressLine} {ad.addressNumber}</p>
																															<p>{ad.district}</p>
																															<p>{ad.province}, {ad.department}</p>
																															<p>Per&uacute;</p>
																														</div>
																													</div>
																												</a>
																											</div>
																										)
																									})
																								}
																							</div>
																							<div className="row">
																								<div className="col-12 text-right">
																									<button type="button" className="btn btn-outline-main mr-2 text-normal" onClick={() => { this.toggleAddress('form'); }}>
																										<i className="fas fa-plus mr-2"></i>
																										Entregar en otra direcci&oacute;n
																									</button>
																									<button className="btn btn-main"
																										disabled={shippingPut.loading || get.loading || this.unavailableProducts()}
																										type="button" onClick={this.setDefaultAddress}>CONTINUAR</button>
																								</div>
																							</div>
																						</div>
																					}
																				</React.Fragment>
																			}

																			{shippingAddress.view === "form" &&
																				<React.Fragment>
																					<p className="info-message">
																						<i className="fas fa-info"></i>
																						Ingrese una direcci&oacute;n de referencia, donde desee recibir y/o recoger sus productos.
																					</p>
																					<div className="row">
																						<div className="col">
																							<div className="form-group">
																								<label>Departamento*</label>
																								<select className="form-control" required name="department" value={shipping.data.department} onChange={(e) => { this.handleInputChange('shipping', e); this.loadProvinces(e, 'shipping') }}>
																									<option value="0" disabled>Seleccionar</option>
																									{
																										ubigeos.departments.map(d => {
																											return (
																												<option key={'d-' + d.id} value={d.id}>{d.name}</option>
																											)
																										})
																									}
																								</select>
																							</div>
																						</div>
																						<div className="col">
																							<div className="form-group">
																								<label>Provincia*</label>
																								<select className="form-control" required name="province" value={shipping.data.province} onChange={(e) => { this.handleInputChange('shipping', e); this.loadDistricts(e, 'shipping') }}>
																									<option value="0">Seleccionar</option>
																									{
																										ubigeos.provinces.map(d => {
																											return (
																												<option key={'p-' + d.id} value={d.id}>{d.name}</option>
																											)
																										})
																									}
																								</select>
																							</div>
																						</div>
																						<div className="col">
																							<div className="form-group">
																								<label>Distrito*</label>
																								<select className="form-control" required name="district" value={shipping.data.district} onChange={(e) => { this.handleInputChange('shipping', e); this.getAddress() }}>
																									<option value="0">Seleccionar</option>
																									{
																										ubigeos.districts.map(d => {
																											return (
																												<option key={'ds-' + d.id} value={d.id}>{d.name}</option>
																											)
																										})
																									}
																								</select>
																							</div>
																						</div>
																					</div>
																					{shipping.data.district != 0 &&
																						<React.Fragment>
																							{(shippingPut.loading || get.loading) &&
																								<div className="text-center pt-5 main-text">
																									<i className="fas fa-spinner fa-pulse fa-2x man-text d-block"></i>
																								</div>
																							}
																							{!(shippingPut.loading || get.loading) && (
																								<React.Fragment>
																									<div className="row">
																										<div className="col">
																											<div className="form-group">
																												<label>Av/Jir&oacute;n/Calle*</label>
																												<input type="text" required className="form-control" name="addressLine" value={shipping.data.addressLine} onChange={(e) => { this.handleInputChange('shipping', e) }} />
																											</div>
																										</div>
																										<div className="w-100"></div>
																										<div className="col-4">
																											<div className="form-group">
																												<label>Nro.*</label>
																												<input type="text" required className="form-control" name="addressNumber" value={shipping.data.addressNumber} onChange={(e) => this.handleInputChange('shipping', e)} />
																											</div>
																										</div>
																										<div className="col-4">
																											<div className="form-group">
																												<label>Interior</label>
																												<input type="text" className="form-control" name="flatNumber" value={shipping.data.flatNumber} onChange={(e) => this.handleInputChange('shipping', e)} />
																											</div>
																										</div>
																										<div className="w-100"></div>
																										<div className="col">
																											<div className="form-group">
																												<label>Referencia de Direcci&oacute;n*</label>
																												<input type="text" className="form-control" name="reference" value={shipping.data.reference} onChange={(e) => this.handleInputChange('shipping', e)} />
																											</div>
																										</div>
																									</div>
																									<div className="row">
																										{(this.unavailableProducts()) &&
																											<div className="col-12">
																												<p className="text-warning">
																													<i className="fas fa-exclamation-circle mr-1"></i>
																											Algunos productos no cuentan con envio para el distrito seleccionado.
																										</p>
																											</div>

																										}
																										<div className="col">
																											<div className="form-group mt-4 text-right">
																												{(shippingAddress.list && shippingAddress.list.length > 0) &&
																													<button type="button" className="btn btn-outline-main mr-2 text-normal" onClick={() => { this.toggleAddress('list'); }}>
																														Volver a la lista de direcciones
																													</button>
																												}
																												<button className="btn btn-main"
																													disabled={shippingPut.loading || get.loading || this.unavailableProducts()}
																													type="button" onClick={this.setDefaultAddress}>CONTINUAR</button>
																											</div>
																										</div>
																									</div>
																								</React.Fragment>
																							)}
																						</React.Fragment>
																					}
																					{shipping.data.district == 0 &&
																						<div className="form-group text-right mt-3">
																							{(shippingAddress.list && shippingAddress.list.length > 0) &&
																								<button type="button" className="btn btn-outline-main text-normal" onClick={() => { this.toggleAddress('list'); }}>
																									Volver a la lista de direcciones
																													</button>
																							}
																						</div>
																					}
																				</React.Fragment>
																			}
																		</React.Fragment>
																	}

																	{!shipping.editingDefault &&
																		<React.Fragment>
																			<p className="info-message">
																				<i className="fas fa-exclamation"></i>
																				Selecciona el tipo de env&iacute;o para ver las opciones disponibles para tus productos
																			</p>
																			{this.renderShippingProducts()}
																			{/* Save shipping */}
																			<div className="form-group mt-4 text-right">
																				<button className="btn btn-main" type="button" onClick={this.saveShippingInfo} disabled={shippingStatus.loading}>
																					CONTINUAR
																					{shippingStatus.loading &&
																						<i className="fas fa-spinner fa-pulse loading-icon"></i>
																					}
																				</button>
																			</div>
																		</React.Fragment>
																	}
																	<div className="checkout__step__form__tabs d-none">
																		<ul className="nav nav-pills nav-justified" id="myTab" role="tablist">
																			<li className="nav-item">
																				<a className="nav-link active" id="home-delivery-tab" data-toggle="tab" href="#home-delivery" role="tab" aria-controls="home-delivery" aria-selected="true">
																					Despacho <span>a domicilio</span>
																				</a>
																			</li>
																			<li className="nav-item">
																				<a className="nav-link" id="store-pickup-tab" data-toggle="tab" href="#store-pickup" role="tab" aria-controls="store-pickup" aria-selected="false">
																					Retiro <span>en tienda</span>
																				</a>
																			</li>
																		</ul>
																	</div>
																	<div className="tab-content pt-4 d-none">
																		<div className="tab-pane fade show active" id="home-delivery" role="tabpanel" aria-labelledby="home-delivery-tab">
																			<h3 className="main-text">Recojo en Tienda</h3>
																			<p className="main-text">
																				Ingresa tu direcci&oacute;n y fecha de entrega:
																			</p>
																			{this.renderPickupOnlyProducts()}
																			<div className="row">
																				<div className="col">
																					<div className="form-group">
																						<label>Departamento*</label>
																						<select className="form-control" name="department" value={shipping.data.department} onChange={(e) => { this.handleInputChange('shipping', e); this.loadProvinces(e, 'shipping') }}>
																							<option value="0" disabled>Seleccionar</option>
																							{
																								ubigeos.departments.map(d => {
																									return (
																										<option key={'d-' + d.id} value={d.id}>{d.name}</option>
																									)
																								})
																							}
																						</select>
																					</div>
																				</div>
																				<div className="col">
																					<div className="form-group">
																						<label>Provincia*</label>
																						<select className="form-control" name="province" value={shipping.data.province} onChange={(e) => { this.handleInputChange('shipping', e); this.loadDistricts(e, 'shipping') }}>
																							<option value="0">Seleccionar</option>
																							{
																								ubigeos.provinces.map(d => {
																									return (
																										<option key={'p-' + d.id} value={d.id}>{d.name}</option>
																									)
																								})
																							}
																						</select>
																					</div>
																				</div>
																				<div className="col">
																					<div className="form-group">
																						<label>Distrito*</label>
																						<select className="form-control" name="district" value={shipping.data.district} onChange={(e) => { this.handleInputChange('shipping', e); this.getAddress() }}>
																							<option value="0">Seleccionar</option>
																							{
																								ubigeos.districts.map(d => {
																									return (
																										<option key={'ds-' + d.id} value={d.id}>{d.name}</option>
																									)
																								})
																							}
																						</select>
																					</div>
																				</div>
																			</div>
																			{shipping.data.district != 0 && (
																				<React.Fragment>
																					<div className="row">
																						<div className="col">
																							<div className="form-group">
																								<label>Av/Jir&oacute;n/Calle*</label>
																								<input type="text" className="form-control" name="addressLine" value={shipping.data.addressLine} onChange={(e) => { this.handleInputChange('shipping', e) }} />
																							</div>
																						</div>
																						<div className="w-100"></div>
																						<div className="col-4">
																							<div className="form-group">
																								<label>Nro.*</label>
																								<input type="text" className="form-control" name="addressNumber" value={shipping.data.addressNumber} onChange={(e) => this.handleInputChange('shipping', e)} />
																							</div>
																						</div>
																						<div className="w-100"></div>
																						<div className="col">
																							<div className="form-group">
																								<label>Referencia de Direcci&oacute;n*</label>
																								<input type="text" className="form-control" name="reference" value={shipping.data.reference} onChange={(e) => this.handleInputChange('shipping', e)} />
																							</div>
																						</div>
																					</div>
																					{this.renderDeliveryWindows()}
																					<div className="row">
																						<div className="col">
																							<div className="form-group mt-4 text-right">
																								<button className="btn btn-main" type="submit">CONTINUAR</button>
																							</div>
																						</div>
																					</div>
																				</React.Fragment>
																			)}
																		</div>
																		<div className="tab-pane fade" id="store-pickup" role="tabpanel" aria-labelledby="store-pickup-tab">
																			<h3 className="main-text">Retiro en tienda</h3>
																			<p className="main-text">
																				Selecciona una tienda y fecha para retirar:
																			</p>
																			{this.renderDeliveryOnlyProducts()}
																			{this.renderOutStockProducts()}
																			{storesModal.pickUpStore.length === 0 && (
																				<React.Fragment>
																					<div className="address-locator">
																						<h2 className="text-center">Vamos sugerir un punto de recogida cerca de usted</h2>
																						<h3 className="text-center">Para esto, necesitamos saber su ubicaci&oacute;n</h3>
																					</div>
																					<div className="map-marker-icon">
																						<svg fill="none" height="106%" version="1.1" viewBox="0 0 212 212" width="106%" xmlns="http://www.w3.org/2000/svg">
																							<g stroke="#FFFFFF" strokeWidth="3">
																								<rect height="146.594378" rx="3" transform="translate(-18.622687, 76.959642) rotate(45.000000) translate(18.622687, -76.959642) " width="146.594378" x="-91.9198759" y="3.66245343"></rect>
																								<rect height="146.594378" rx="3" transform="translate(154.068076, -49.144775) rotate(45.000000) translate(-154.068076, 49.144775) " width="146.594378" x="80.7708872" y="-122.441964"></rect>
																								<rect height="146.594378" rx="3" transform="translate(194.228718, 170.132333) rotate(45.000000) translate(-194.228718, -170.132333) " width="146.594378" x="120.93153" y="96.8351442"></rect>
																								<rect height="146.594378" rx="3" transform="translate(47.240767, 263.305024) rotate(45.000000) translate(-47.240767, -263.305024) " width="146.594378" x="-26.0564221" y="190.007835"></rect>
																								<rect height="146.594378" rx="3" transform="translate(225.521891, 86.630325) rotate(45.000000) translate(-225.521891, -86.630325) " width="146.594378" x="152.224702" y="13.3331362"></rect>
																								<rect height="146.594378" rx="3" transform="translate(-1.755217, 3.064060) rotate(45.000000) translate(1.755217, -3.064060) " width="146.594378" x="-75.052406" y="-70.2331289"></rect>
																							</g>
																							<path d="M33,0 C14.7792857,0 0,15.048 0,33.6 C0,58.8 33,96 33,96 C33,96 66,58.8 66,33.6 C66,15.048 51.2207143,0 33,0 Z M33,45.6 C26.4942857,45.6 21.2142857,40.224 21.2142857,33.6 C21.2142857,26.976 26.4942857,21.6 33,21.6 C39.5057143,21.6 44.7857143,26.976 44.7857143,33.6 C44.7857143,40.224 39.5057143,45.6 33,45.6 Z" fill="#FFFFFF" id="Shape" transform="translate(66.666667, 52.208835)"></path>
																						</svg>
																					</div>
																					<div className="text-center address-locator-actions">
																						<button className="btn btn-main" type="button">Usar mi Ubicaci&oacute;n actual</button>
																						<div className="w-100"></div>
																						<a role="button" onClick={(e) => { this.openModal('autocomplete') }}>Buscar una tienda manualmente</a>
																					</div>

																				</React.Fragment>
																			)}
																			{this.renderSelectedPickupStores()}
																			{storesModal.pickUpStore.length !== 0 && (
																				<div className="row">
																					<div className="col">
																						<div className="form-group mt-4 text-right">
																							<button className="btn btn-main" type="submit">CONTINUAR</button>
																						</div>
																					</div>
																				</div>
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</form>
													</div>
												</React.Fragment>
											}

											{(!shipping.editing || userStatus.view == 'form') &&
												<React.Fragment>
													<div className="checkout__step__summary mt-4" id="shippingSummary">
														<div className="card">
															<div className="card-body">
																<h3 className="main-text">
																	Paso 2 - M&eacute;todo de Entrega
																</h3>
																{userStatus.view == 'form' &&
																	<React.Fragment>
																		<p className="mb-0">A&uacute;n falta llenar algunos datos.</p>
																	</React.Fragment>
																}
																{userStatus.view == 'summary' &&
																	<React.Fragment>

																		{
																			this.renderStoreProductsDetails(shipping.items)
																		}

																		{storesModal.pickUpStore.length > 0 &&
																			storesModal.pickUpStore.map((el, i) => {
																				return (
																					<div key={'pup-' + i}>
																						<h4 className="mb-3 mt-3">
																							Recojo en Tienda
																							</h4>
																						<div className="checkout__step__summary__info">
																							<div className="checkout__step__summary__info__icon map-marker">
																							</div>
																							<div className="checkout__step__summary__info__content">
																								<p>{el.pickupStoreInfo.friendlyName}</p>
																							</div>
																						</div>
																						{(!el.pickupDate.min && !el.pickupDate.max) &&
																							<React.Fragment>
																								<p className="mt-3 mb-3">(Agendada)</p>
																								<div className="checkout__step__summary__info">
																									<div className="checkout__step__summary__info__icon delivery">
																									</div>
																									<div className="checkout__step__summary__info__content">
																										<h5 className="main-text">Fecha de Entrega</h5>
																										<p>{this.formatPickupDate(el, el.pickupDate.pickupDate.toString())}</p>
																									</div>
																								</div>
																							</React.Fragment>
																						}
																					</div>
																				)
																			})
																		}
																		<div className="mt-3">
																			<button className="btn btn-main btn-block" type="button" onClick={this.handleEditShipping}>EDITAR M&Eacute;TODO DE ENTREGA</button>
																		</div>
																	</React.Fragment>
																}
															</div>
														</div>
													</div>
												</React.Fragment>
											}
										</div>


										<div className="checkout__step">
											{(userStatus.view == 'form' || shipping.editing) &&
												<React.Fragment>
													<div className="checkout__step__summary mt-4 mb-4">
														<div className="card">
															<div className="card-body">
																<h3 className="main-text">
																	Paso 3 - M&eacute;todo de Pago
														</h3>
																<p className="mb-0">A&uacute;n falta llenar algunos datos.</p>
															</div>
														</div>
													</div>
												</React.Fragment>
											}
											<span className="d-none"></span>
											{/* (userStatus.view === 'summary' && !shipping.editing) */}
											{(userStatus.view === 'summary' && !shipping.editing) &&
												<React.Fragment>
													<div className="checkout__step__form">
														<form noValidate id="paymentForm">
															<div className="card card-default">
																<div className="card-body">
																	<h3 className="main-text">Paso 3 - M&eacute;todo de Pago</h3>
																	<div className="row">
																		<div className="col">
																			<div className="form-group d-flex flex-wrap mb-0">
																				<label className="main-text mb-2 w-100">Ingresa tu m&eacute;todo de pago:</label>
																				<div className="form-check pm-check mr-3">
																					<input className="form-check-input" type="radio" value="1" id="cc" required name="type" checked={payment.data.type == '1'} onChange={(e) => { this.handleInputChange('payment', e); this.validateIbkAuth(e) }} />
																					<label className="form-check-label d-flex align-items-center" htmlFor="cc">
																						Tarjeta de Cr&eacute;dito y D&eacute;bito
																						<span className="cc-icon ml-2"></span>
																					</label>
																				</div>
																				{
																					(enableBenefits === 'true') &&
																					<div className="form-check pm-check mr-3">
																						<input className="form-check-input" type="radio" value="2" id="mb" required name="type" checked={payment.data.type == '2'} onChange={(e) => { this.handleInputChange('payment', e); this.validateIbkAuth(e) }} />
																						<label className="form-check-label d-flex align-items-center pt-0" htmlFor="mb">
																							<span className="benefits-logo"></span>
																						</label>
																					</div>
																				}
																				<div className="form-check pm-check mr-3">
																					<input className="form-check-input" type="radio" value="3" id="cibk" required name="type" checked={payment.data.type == '3'} onChange={(e) => { this.handleInputChange('payment', e); this.validateIbkAuth(e) }} />
																					<label className="form-check-label d-flex align-items-center pt-0" htmlFor="cibk">
																						Div&iacute;delo en cuotas con
																						<span className="interbank-logo ml-2"></span>
																					</label>
																				</div>
																			</div>
																		</div>
																	</div>
																	{(miles.data && payment.data.type == 2 && !ibkMiles.loading && ibkMiles.data && customerId && !get.loading) &&
																		<React.Fragment>
																			{miles.data.total > 0 &&
																				<React.Fragment>
																					<div className="row mt-4">
																						<div className="col-12 col-md-7">
																							<div className="miles-control">
																								<h5 className="">Tus millas acumuladas: {miles.data.total.toLocaleString('en')}</h5>
																								<p>
																									<b>Quedarían disponibles: {(miles.data.total - miles.data.selected).toLocaleString('en')} millas</b>
																								</p>
																								<div className="form-group">
																									<label>¿Cuántas millas deseas utilizar?</label>
																									<div className="input-group">
																										<div className="input-group-prepend">
																											<button className="btn btn-outline-secondary" type="button" onClick={(e) => { this.changeMilesAmount(e, -1) }}>-</button>
																										</div>
																										<input type="text" className="form-control" name="selected" value={miles.data.selected} onChange={(e) => { this.handleInputChange('miles', e); this.calculateExchange(e) }} />
																										<div className="input-group-append">
																											<button className="btn btn-outline-secondary" type="button" onClick={(e) => { this.changeMilesAmount(e, 1) }}>+</button>
																										</div>
																									</div>
																									<label className="exchange"><span>Equivalen a un descuento de: </span>&nbsp;S/{this.calculateMilesDiscount()}</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="row">
																						<div className="col-12 col-md-7">
																							<div className="card exchange-total">
																								<div className="card-body">
																									Total a pagar con tarjeta: S/ {(this.calculateRestAmount()).toLocaleString('en')}
																								</div>
																							</div>
																						</div>
																					</div>
																				</React.Fragment>
																			}
																			{(miles.data.total === 0 && miles.loaded) &&
																				<React.Fragment>
																					<div className="text-danger">
																						<div className="miles-control">
																							{/* <h5 className="">Tus millas acumuladas: {miles.data.total.toLocaleString('en')}</h5> */}
																							<p className="text-danger">
																								<b>Usted no cuenta con millas disponibles</b>
																							</p>
																						</div>
																					</div>
																				</React.Fragment>
																			}
																		</React.Fragment>
																	}
																	{(ibkMiles.loading) &&
																		<React.Fragment>
																			<div className="text-center d-flex align-items-center main-text mb-3">
																				<i className="fas fa-spinner fa-pulse mr-2"></i>
																				<span className="ml-2">Cargando tus millas ...</span>
																			</div>
																		</React.Fragment>
																	}
																	{(ibkMiles.error) &&
																		<React.Fragment>
																			<div className="row">
																				<div className="col-md-8 d-flex align-items-start text-danger mb-3">
																					<i className="fas fa-exclamation-circle mt-2"></i>
																					<span className="ml-2">Lo sentimos. Tenemos problemas para mostrarle la cantidad correcta de Millas a nuestros clientes. Por favor, inténtalo nuevamente en un par de horas.</span>
																				</div>
																			</div>
																		</React.Fragment>
																	}
																	{(true) &&
																		<React.Fragment>
																			<div className="row">
																				<div className="col-12 col-md-7">
																					{(this.calculateRestAmount() > 0 || !customerId || !ibkMiles.data || payment.data.type == 1) &&
																						<React.Fragment>
																							{payment.data.type == 1 &&
																								<h5>Paga con tu tarjeta de cr&eacute;dito o d&eacute;bito</h5>
																							}
																							{payment.data.type == 2 &&
																								<h5>Paga con tus millas Benefit</h5>
																							}
																							{payment.data.type == 3 &&
																								<h5>Pasa a cuotas con Div&iacute;delo</h5>
																							}
																							<div className={'form-group card-input ' + payment.validCard}>
																								<label>
																									N&uacute;mero de Tarjeta
																									<div className="cc-types">
																										<div className="form-check form-check-inline visa">
																											<input className="form-check-input" required type="radio" name="cardType" id="visa" value="visa" checked={payment.data.cardType === 'visa'} onChange={(e) => this.handleInputChange('payment', e)} />
																											<label className="form-check-label" htmlFor="visa"></label>
																										</div>
																										<div className="form-check form-check-inline amex">
																											<input className="form-check-input" required type="radio" name="cardType" id="amex" value="amex" checked={payment.data.cardType === 'amex'} onChange={(e) => this.handleInputChange('payment', e)} />
																											<label className="form-check-label" htmlFor="amex"></label>
																										</div>
																										<div className="form-check form-check-inline diners">
																											<input className="form-check-input" required type="radio" name="cardType" id="diners" value="diners" checked={payment.data.cardType === 'diners'} onChange={(e) => this.handleInputChange('payment', e)} />
																											<label className="form-check-label" htmlFor="diners"></label>
																										</div>
																										<div className="form-check form-check-inline mc">
																											<input className="form-check-input" required type="radio" name="cardType" id="mc" value="mc" checked={payment.data.cardType === 'mastercard'} onChange={(e) => this.handleInputChange('payment', e)} />
																											<label className="form-check-label" htmlFor="mc"></label>
																										</div>
																									</div>
																								</label>
																								{payment.data.type == 3 &&
																									<React.Fragment>
																										{(payment.showIsIbkCardMessage && payment.isIbkCard == 'valid') &&
																											<div className="ibk-client text-success valid">
																												<i className="fas fa-check-circle mr-2"></i>
																												Cliente Interbank
																											</div>
																										}
																										{(payment.showIsIbkCardMessage && payment.isIbkCard == 'invalid') &&
																											<div className="ibk-client text-danger invalid">
																												<i className="fas fa-times-circle mr-2"></i>
																												Coloca una tarjeta de crédito Interbank
																											</div>
																										}
																									</React.Fragment>
																								}

																								<input type="text" className={payment.data.type == 3 ? (payment.isIbkCard == 'valid' ? 'form-control valid' : (payment.isIbkCard == 'invalid' ? 'form-control invalid' : 'form-control')) : 'form-control'} name="cardNumber" maxLength={payment.cardLength} value={payment.data.cardNumber} onChange={(e) => { this.handleInputChange('payment', e); this.validateCardNumber(e); this.getDiscount(e); }} onBlur={(e) => { this.getDiscount(e); this.validateIbkCardNumber(e) }} />
																								{payment.data.type != 3 &&
																									<i className="fas fa-check pb-1 form-icon"></i>
																								}
																							</div>

																							{payment.data.type == 3 &&
																								<React.Fragment>
																									<div className="form-group">
																										<label>Ingresa tu DNI</label>
																										<input type="text" className="form-control" required name="placeHolderDocumentNumber" value={payment.data.placeHolderDocumentNumber} onChange={(e) => this.handleInputChange('payment', e)} />
																									</div>
																									<div className="form-group">
																										{
																											this.renderInstallmentsList()
																										}
																										{payment.isIbkCard == 'valid' &&
																											<small>* TEA 25% aplicable a esta compra</small>
																										}
																										{payment.isIbkCard != 'valid' &&
																											<small>* Tasa de interés preferencial por ser cliente Interbank</small>
																										}
																									</div>
																								</React.Fragment>
																							}

																							<div className="form-group">
																								<label>Datos del titular</label>
																								<input type="text" className="form-control" required name="placeHolder" value={payment.data.placeHolder} onChange={(e) => this.handleInputChange('payment', e)} />
																							</div>
																							<div className="row">
																								<div className="col-md-8">
																									<div className="form-group">
																										<label>Fecha de Vencimiento</label>
																										<div className="row">
																											<div className="col-6 col-md-6">
																												<select className="form-control" required name="month" value={payment.data.month} onChange={(e) => this.handleInputChange('payment', e)}>
																													<option value="0" disabled>MM</option>
																													{['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
																														return (
																															<option key={'m-' + month} value={month}>{month}</option>
																														)
																													})}
																												</select>
																											</div>
																											<div className="col-6 col-md-6">
																												<select className="form-control" required name="year" value={payment.data.year} onChange={(e) => this.handleInputChange('payment', e)}>
																													<option value="0" disabled>AA</option>
																													{['19', '20', '21', '22', '23', '24', '25', '26'].map((year) => {
																														return (
																															<option key={'y-' + year} value={year}>{year}</option>
																														)
																													})}
																												</select>
																											</div>
																										</div>
																									</div>
																								</div>
																								<div className="col-md-4">
																									<div className="form-group">
																										<label className="d-flex cvv-input">
																											CVV
																											<span className="cvv-image"></span>
																										</label>
																										<input type="text" className="form-control" required name="unmaskedCvv" value={payment.data.unmaskedCvv} onChange={(e) => this.handleInputChange('payment', e)} onBlur={(e) => this.onCvvBlur()} />
																									</div>
																								</div>
																							</div>
																						</React.Fragment>
																					}
																					{/* <div className="form-group">
																						<label>Pasar a cuotas</label>
																						<select className="form-control" required name="installments" value={payment.data.installments} onChange={(e) => this.handleInputChange('payment', e)}>
																							<option value="0" disabled>Cuotas</option>
																							<option value="1">Total - S/ {(get.draft.total).toFixed(2)}</option>
																						</select>
																					</div> */}
																					<div className="form-group billing-check">
																						<div className="custom-control custom-checkbox">
																							<input type="checkbox" className="custom-control-input" id="useShippingAddressCheck" name="useShippingAddress" checked={useShippingAddress} onChange={(e) => { this.onCheckCopyShippingInfo(e) }} />
																							<label className="custom-control-label" htmlFor="useShippingAddressCheck">
																								<b>Direcci&oacute;n de Facturaci&oacute;n</b>
																								<span>Igual a la direcci&oacute;n de envio: {shipping.data.addressLine}</span>
																							</label>
																						</div>
																					</div>
																					{(!useShippingAddress) &&
																						<React.Fragment>
																							<div className="form-group">
																								<label>Departamento</label>
																								<select className="form-control" required name="department" value={billing.data.department} onChange={(e) => { this.handleInputChange('billing', e); this.loadProvinces(e, 'billing') }}>
																									<option value="0" disabled>Seleccionar</option>
																									{
																										billingUbigeos.departments.map(d => {
																											return (
																												<option key={'bd-' + d.id} value={d.id}>{d.name}</option>
																											)
																										})
																									}
																								</select>
																							</div>
																							<div className="form-group">
																								<label>Provincia</label>
																								<select className="form-control" required name="province" value={billing.data.province} onChange={(e) => { this.handleInputChange('billing', e); this.loadDistricts(e, 'billing') }}>
																									<option value="0">Seleccionar</option>
																									{
																										billingUbigeos.provinces.map(d => {
																											return (
																												<option key={'bp-' + d.id} value={d.id}>{d.name}</option>
																											)
																										})
																									}
																								</select>
																							</div>
																							<div className="form-group">
																								<label>Distrito</label>
																								<select className="form-control" required name="district" value={billing.data.district} onChange={(e) => { this.handleInputChange('billing', e); }}>
																									<option value="0">Seleccionar</option>
																									{
																										billingUbigeos.districts.map(d => {
																											return (
																												<option key={'bds-' + d.id} value={d.id}>{d.name}</option>
																											)
																										})
																									}
																								</select>
																							</div>
																							<div className="form-group">
																								<label>Av/Jir&oacute;n/Calle</label>
																								<input type="text" required className="form-control" name="addressLine" value={billing.data.addressLine} onChange={(e) => { this.handleInputChange('billing', e) }} />
																							</div>
																							<div className="form-group">
																								<label>Nro.</label>
																								<input type="text" required className="form-control" name="addressNumber" value={billing.data.addressNumber} onChange={(e) => this.handleInputChange('billing', e)} />
																							</div>
																						</React.Fragment>
																					}
																					{(payment.data.type === 1 || payment.data.type === 3) &&
																						<React.Fragment>
																							<div className="mt-4">
																								<div className="form-group billing-check">
																									<div className="custom-control custom-checkbox">
																										<input type="checkbox" className="custom-control-input" id="getInvoice" name="getInvoice" checked={orderType === 'factura'} onChange={(e) => { this.handleOrderType() }} />
																										<label className="custom-control-label" htmlFor="getInvoice">
																											<b>Recibir factura</b>
																											<span>Ingrese su raz&oacute;n social y n&uacute;mero de RUC </span>
																										</label>
																									</div>
																								</div>
																							</div>
																							{orderType == 'factura' &&
																								<React.Fragment>
																									<div className="row">
																										<div className="col">
																											<div className="form-group">
																												<label>Raz&oacute;n Social*</label>
																												<input type="text" className="form-control" required name="entityName" id="entityName" value={company.data.entityName} onChange={(e) => this.handleInputChange('company', e)} />
																											</div>
																										</div>
																										<div className="col">
																											<div className="form-group">
																												<label>RUC *</label>
																												<input type="text" className="form-control" required name="entityIdentificationNumber" id="entityIdentificationNumber" value={company.data.entityIdentificationNumber} onChange={(e) => this.handleInputChange('company', e)} />
																											</div>
																										</div>
																									</div>
																								</React.Fragment>
																							}
																						</React.Fragment>
																					}
																				</div>
																			</div>
																		</React.Fragment>
																	}
																</div>
															</div>
														</form>
													</div>
												</React.Fragment>
											}
										</div>
									</div>

									<div className="col-md-5">
										<div className="checkout__summary">

											<div className="card">
												<div className="card-header text-uppercase text-center">
													Resumen de tu compra
													</div>
												<div className="card-body">
													<div className="checkout__summary__items">
														<table className="table">
															<tbody>
																{this.renderCart()}
															</tbody>
														</table>
													</div>
												</div>
												{(start.data && !get.draft) &&
													<React.Fragment>
														<div className="card-footer">
															<table className="table">
																<tbody>
																	<tr>
																		<td>
																			Subtotal
															</td>
																		<td className="text-right">
																			S/ {start.data.subTotal.toFixed(2)}
																		</td>
																	</tr>
																	{start.data.discount < 0 &&
																		<tr>
																			<td>
																				Descuento
																</td>
																			<td className="text-right">
																				S/ {start.data.discount.toFixed(2)}
																			</td>
																		</tr>
																	}
																	<tr className="total">
																		<td>
																			Total
															</td>
																		<td className="text-right">
																			S/ {(start.data.total).toFixed(2)}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</React.Fragment>
												}
												{get.draft && (
													<React.Fragment>
														<div className="card-footer">
															<table className="table">
																<tbody>
																	<tr>
																		<td>
																			Subtotal
																		</td>
																		<td className="text-right">
																			S/ {get.draft.subTotal.toFixed(2)}
																		</td>
																	</tr>
																	<tr>
																		<td>
																			Costo de Env&iacute;o
																		</td>
																		{(shippingStatus.view === "form" && !shipping.loadedFromStorage) &&
																			<td className="text-right">
																				Por calcular
																			</td>
																		}
																		{(shippingStatus.view !== "form" || shipping.loadedFromStorage) &&
																			<td className="text-right">
																				{get.draft.shippingCost > 0 ? 'S/' + get.draft.shippingCost.toFixed(2) : 'Gratis'}
																			</td>
																		}
																	</tr>
																	{(payment.data.type == 2 && miles && miles.data && miles.data.selected > 0) &&
																		<tr>
																			<td>
																				Millas a canjear
																		<small className="d-block">({miles.data.selected.toLocaleString('en')} Millas)</small>
																			</td>
																			<td className="text-right">
																				S/ {this.calculateMilesDiscount()}
																			</td>
																		</tr>
																	}
																	{get.draft.discount < 0 &&
																		<tr>
																			<td>
																				Descuento
																	</td>
																			<td className="text-right">
																				S/ {get.draft.discount.toFixed(2)}
																			</td>
																		</tr>
																	}
																	<tr className="total">
																		<td>
																			Total
																</td>
																		<td className="text-right">

																			{(payment.data.type == 2 && miles && miles.data && miles.data.selected > 0) &&
																				<React.Fragment>
																					S/ {(this.calculateRestAmount()).toFixed(2)}
																				</React.Fragment>
																			}
																			{((payment.data.type == 1 || payment.data.type == 3) || (miles && miles.data && miles.data.selected == 0)) &&
																				<React.Fragment>
																					S/ {(get.draft.total).toFixed(2)}
																				</React.Fragment>
																			}
																		</td>
																	</tr>
																</tbody>
															</table>
															<div className="custom-control custom-checkbox">
																<input type="checkbox" className="custom-control-input" id="termsCheck" name="terms" checked={policies.terms} onChange={(e) => { this.onChangePolicyCheck(e) }} />
																<label className="custom-control-label" htmlFor="termsCheck">
																	Acepto los <a href="https://www.shopstar.pe/Institucional/terminos-y-condiciones" target="_blank">t&eacute;rminos y condiciones.</a>
																</label>
															</div>
															<div className="custom-control custom-checkbox">
																<input type="checkbox" className="custom-control-input" id="personalDataCheck" name="personalData" checked={policies.personalData} onChange={(e) => { this.onChangePolicyCheck(e) }} />
																<label className="custom-control-label" htmlFor="personalDataCheck">
																	Acepto los <a href="https://www.shopstar.pe/Institucional/terminos-y-condiciones-pago" target="_blank">t&eacute;rminos y condiciones de pago y canje.</a>
																</label>
															</div>
															<div className="custom-control custom-checkbox mb-4">
																<input type="checkbox" className="custom-control-input" id="privacyCheck" name="privacy" checked={policies.privacy} onChange={(e) => { this.onChangePolicyCheck(e) }} />
																<label className="custom-control-label" htmlFor="privacyCheck">
																	Autorizo a Shopstar.pe el uso de mis datos personales para finalidades opcionales previstas en la <a href="https://shopstar.pe/Institucional/politica-de-privacidad" target="_blank">Pol&iacute;tica de Privacidad.</a>
																</label>
															</div>
															<div className="text-center">
																<button className="btn btn-main" onClick={this.handleBuyNow} disabled={this.checkData()}>
																	COMPRAR AHORA
															<i className="fas fa-spinner fa-pulse loading-icon d-none"></i>
																</button>
															</div>
														</div>
													</React.Fragment>
												)}
											</div>
										</div>
									</div>
								</div>
							</React.Fragment>
						}
					</div>
				</div>

				<div id="footer">
					<div className="center-wrap">
						<div className="row">
							<div className="col -w1 payment-providers">
								<ul className="mdpg items">
									<li className="item title-card-footer mr-3">
										<i className="mr-2">
											<img src="https://shopstar.pe/arquivos/pagos-gris.png" alt="Shopstar Checkout" width="30" />
										</i>
										<span>MEDIOS DE PAGO:</span></li>

									<li className="itemicono-card-gris mr-2">
										<i>
											<img src="https://shopstar.pe/arquivos/card-visa.png" alt="Shopstar Checkout" width="50" />
										</i>
									</li>
									<li className="item icono-card-gris mr-2">
										<i>
											<img src="https://shopstar.pe/arquivos/card-mastercard.png" alt="Shopstar Checkout" width="50" />
										</i>
									</li>
									<li className="item icono-card-gris">
										<i>
											<img src="https://shopstar.pe/arquivos/card-americanexpress.png" alt="Shopstar Checkout" width="50" />
										</i>
									</li>
								</ul>
								{/* <ul className="mdpg items">
									<li className="item title-card-footer">
										PAGO SEGURO:
									</li>
									<li className="item icono-card-gris">
										<i>
											<img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/kushki.svg" className="kushki-icon" alt="Shopstar Checkout" width="95" />
										</i>
									</li>
								</ul> */}
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade stores-modal" id="storesModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
							<div className="modal-body stores-map">
								{shippingStatus.modalView === 'autocomplete' &&
									<React.Fragment>
										<div className="stores-map__search">
											<i className="fas fa-map-marker-alt"></i>
											<h2>Encuentre puntos de retirada cercanos</h2>
											<h4>&iexcl;Cuanto m&aacute;s espec&iacute;fica sea su b&uacute;squeda, mejores ser&aacute;n los resultados!</h4>
											<div className="form-group">
												<i className="fas fa-search"></i>
												<Autocomplete
													onPlaceSelected={(place) => { this.selectAddress(place) }}
													types={[]}
													componentRestrictions={{ country: "pe" }}
												/>
												<button className="btn btn-link">
													<i className="fas fa-crosshairs"></i>
												</button>
											</div>
										</div>
									</React.Fragment>
								}

								{shippingStatus.modalView === 'map' &&
									<React.Fragment>
										<div className="stores-map__map">
											<div className="card stores-map__map__list">
												<div className="card-header">
													Detalles de la tienda
											</div>
												<div className="card-body">
													{!storesModal.selectedStore &&
														<React.Fragment>
															<div className="form-group">
																<Autocomplete
																	onPlaceSelected={(place) => { this.selectAddress(place) }}
																	types={[]}
																	componentRestrictions={{ country: "pe" }}
																/>
															</div>
															<h4 className="text-center main-text">
																Producto disponible en las siguientes tiendas
															</h4>
															<div className="stores-map__map__list__items">
																{this.renderStores()}
															</div>
														</React.Fragment>
													}
													{storesModal.selectedStore &&
														<React.Fragment>
															<a role="button" onClick={this.clearSelectedStore} className="btn-back">
																<i className="fas fa-angle-left"></i>
																Ver todos los puntos de recogida
																</a>
															<div className="stores-map__map__list__detail">
																<div className="card">
																	<div className="card-body">
																		<div className="location-icon">
																			<i className="fas fa-map-marker-alt d-block main-text"></i>
																			<span>1000+ km</span>
																		</div>
																		<div className="store-info">
																			<h4>{storesModal.selectedStore.pickupStoreInfo.friendlyName}</h4>
																			<p className="mb-0">{storesModal.selectedStore.pickupStoreInfo.address.street} {storesModal.selectedStore.pickupStoreInfo.address.number} {storesModal.selectedStore.pickupStoreInfo.address.complement}</p>
																			<p className="mb-0">{storesModal.selectedStore.pickupStoreInfo.address.state}, {storesModal.selectedStore.pickupStoreInfo.address.city}, {storesModal.selectedStore.pickupStoreInfo.address.neighborhood}</p>
																		</div>
																	</div>
																	<div className="card-footer">
																		<span>Gratis</span>
																		<span>Listo en hasta 1 d&iacute;a</span>
																	</div>
																</div>
																<p>Disponibilidad de productos en este punto</p>
																<div className="product-image">
																	{this.renderProductsBySla()}
																</div>
																<p>Otras informaciones</p>
																<h4>{storesModal.selectedStore.pickupStoreInfo.friendlyName}</h4>
																<p>Horarios de atenci&oacute;n</p>
																<ul className="list-unstyled pl-0">
																	{this.rederStoreScheadule()}
																</ul>
															</div>
															<div className="form-group">
																<button className="btn btn-main text-uppercase btn-block" onClick={this.savePickupStore}>Elegir esta tienda</button>
															</div>
														</React.Fragment>
													}
												</div>
											</div>
											<MyMapComponent
												isMarkerShown
												loadingElement={<div style={{ height: `100%` }} />}
												containerElement={<div style={{ height: `560px` }} />}
												mapElement={<div style={{ height: `100%` }} />}
											/>re
										</div>
									</React.Fragment>
								}
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade loading-payment-modal" id="loadingPaymentModal" role="dialog" aria-labelledby="loadingPaymentModalLabel" aria-hidden="true" data-backdrop="static">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-body">
								<div className="loading-icon">
									<i className="fas fa-spinner fa-pulse"></i>
								</div>
								<h3 className="main-text text-center">&iexcl;Aguarda un instante!</h3>
								<p className="text-center">
									Tu pedido est&aacute; siendo procesado
								<br />
								y puede tardar unos segundos
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade" id="errorPaymentModal" role="dialog" aria-labelledby="loadingPaymentModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-body">

								{(transaction.error && transaction.error.status === 400) &&
									<h3 className="main-text text-center">{transaction.error.statusText}</h3>
								}
								{(transaction.error && transaction.error.status !== 400) &&
									<h3 className="main-text text-center">Algo ha ido mal</h3>
								}
								<p className="text-center">Por favor intentalo nuevamente</p>
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade" id="kushkiErrorModal" role="dialog" aria-labelledby="loadingPaymentModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-body">
								<h3 className="main-text text-center">Algo ha ido mal</h3>
								<p className="text-center">Por favor intentalo nuevamente</p>
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade" id="benefitCardErrorModal" role="dialog" aria-labelledby="benefitCardErrorModal" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-body">
								<h3 className="main-text text-center">¡Ups!</h3>
								<p className="text-center">Para poder usar tus millas, debes ingresar una tarjeta de cr&eacute;dito Interbank.</p>
								<div className="text-center">
									<button type="button" className="btn btn-main pl-4 pr-4" data-dismiss="modal">Entendido</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade" id="validateMilesModal" role="dialog" aria-labelledby="validateMilesModalLabel" data-backdrop="static" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-body">
								<p className="text-center">
									Te llevaremos a una página segura de <b>Interbank</b> para validar tus datos y millas. ¿Estás de acuerdo?
								</p>
								<button className="btn btn-main" type="button" onClick={(e) => { this.redirectInterbankLogin() }}>Sí, ¡vamos!</button>
								<button className="btn btn-outline-main" type="button" onClick={(e) => { this.closeValidateMilesModal() }}>No por ahora</button>
								<p>
									<small>
										¿Todavía no tienes tarjeta de débito interbank?
									<br />
									Solicitala <a href="https://interbank.pe/cuentas/cuentas-ahorro/cuenta-simple?rfid=navegacion:menu-home:link" target="_blank" alt="Solicita tu tarjeta Interbank">aquí</a>
									</small>
								</p>
							</div>
							<div className="modal-footer">
								<span>Página segura. Cuenta con respaldo de:</span>
								<img src="https://ibkshopstarsasprod.blob.core.windows.net/images/Site/ibklogocolor.png" alt="Interbank" />
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade" id="ibkAuthenticationModal" role="dialog" aria-labelledby="ibkAuthenticationModalLabel" aria-hidden="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-body">
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
								<h4>
									<span>Por favor ingresa tus datos</span>
									<span>para validar tus millas</span>
								</h4>
								<form>
									<div className="row">
										<div className="col-md-12">
											<div className="form-group">
												<input className="form-control" type="tel" placeholder="N&uacute;mero de tarjeta" name="creditCard" value={auth.data.creditCard} onChange={(e) => this.handleInputChange('auth', e)} />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-5">
											<div className="form-group">
												<select className="form-control" name="documentType" value={auth.data.documentType} onChange={(e) => this.handleInputChange('auth', e)}>
													<option value="DNI">DNI</option>
													<option value="CE">CE</option>
												</select>
											</div>
										</div>
										<div className="col-md-7">
											<div className="form-group">
												<input className="form-control" type="text" placeholder="N&uacute;mero de documento" name="documentNumber" value={auth.data.documentNumber} onChange={(e) => this.handleInputChange('auth', e)} />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<div className="form-group">
												<span>¡</span>
												<input className="form-control" type="password" placeholder="Clave Web" name="password" value={auth.data.password} onChange={(e) => this.handleInputChange('auth', e)} />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<div className="form-group">
												<button className="btn btn-ibk" onClick={this.ibkAuthenticate} type="button">Aceptar</button>
											</div>
										</div>
									</div>
								</form>
							</div>
							<div className="modal-footer">
								<p>P&aacute;gina segura. Cuenta con respaldo de Interbank</p>
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade" id="milesLoginModal" role="dialog" aria-labelledby="milesLoginModalLabel" aria-hidden="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-body">
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
								<div>
									{this.showMilesLoginForm()}
								</div>
							</div>
						</div>
					</div>
				</div>


				<div id="warningToast" className="toast bg-warning" role="alert" aria-live="assertive" aria-atomic="true">
					<button type="button" className="ml-2 mb-1 close mr-1" data-dismiss="toast" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
					<div className="toast-body">
						Por favor revise la informaci&oacute;n del formulario, e intente nuevamente.
					</div>
				</div>
			</div>
		);
	}
}

//export default geolocated({
//	positionOptions: {
//		enableHighAccuracy: false,
//	},
//	userDecisionTimeout: 5000,
//})(CheckoutView);
export default CheckoutView;
