import { auth, cartCashAndPoints, customerActionsLogging } from '../../../../services/integrations/MuscleService';

export const postCart = async (checkout, userTotalMiles, ibkPrices) => {
    var result = null;
    const LOYALTY_PLAN_CODE = "Millas Benefit"; //Millas Benefit
    const LOYALTY_PLAN_NAME = "Millas Benefit";
    const CONVERSION_RATE = 0.03; //usar 1 ya que 1 milla en shopstar equiv. 1 milla en mscl
    //__code__    83b6ba2f81564c91bae7a2c1b9f335da
    let customerId = localStorage.getItem("__customerId__"); //0014311611

    let itemsCheckout = checkout.items.map((e) => {
        let ibkPriceItem = null;
        if (ibkPrices.length > 0) {
            ibkPriceItem = ibkPrices.find(element => element.id == e.sku);
        }
        return {
            "sku": e.sku,
            "product_name": e.productName,
            "category": "",
            "brand": "",
            "free_shipping": "",
            "product_condition": "",
            "guarantee": "",
            "weigth": "",
            "made_in": "",
            "product_raw_cost_USD": e.total,
            "product_cost_USD": (ibkPriceItem) ? ibkPriceItem.totalIbk : e.total,
            "description": "",
            "quantity": e.quantity
        }
    });

    console.log("checkout.additionalAddress: " + checkout.additionalAddress);
    let payload = {
        "client": {
            "user_id": process.env.REACT_APP_NAME === "Shopstar dev" ? customerId : customerId,
            "bank_uniq_id": process.env.REACT_APP_NAME === "Shopstar dev" ? customerId : customerId,
            // "user_id": "RT87191",
            // "bank_uniq_id": "RT87191",
            "authorize_personal_data": checkout.customerAuthorizePersonalData,
            "country": "PE",
            "gender": "",
            "date_of_birth": "",
            "address": {
                "state": "",// checkout.additionalAddress[0].department,
                "city": "", // checkout.additionalAddress[0].district,
                "zipcode": "" // checkout.additionalAddress[0].stateCode
            },
            "marital_status": "",
            "credit_card_limit_USD": 0.00,
            "available_credit_card_USD": 0.00,
            "number_of_flights_last_year": 0,
            "amount_spent_flights_last_year_USD": 0.00,
            "monthly_net_income_USD": 0.00,
            "foreign_currency_generator": "",
            "academic_degree": "",
            "industry": "",
            "current_job": "",
            "own_home": "",
            "loyalty_plans": [
                {
                    "loyalty_member_number": customerId,
                    "loyalty_plan_code": LOYALTY_PLAN_CODE,
                    "loyalty_plan_name": LOYALTY_PLAN_NAME,
                    "points": userTotalMiles,
                    "conversion_rate": CONVERSION_RATE
                }
            ],
            "cash_points_equivalency_USD": 0.00,
            "client_points": userTotalMiles,
            "client_transactions": 0.1,
            "client_spent_cash_ratio": 0.1,
            "client_spent_cash": 0.1
        },
        "cart": {
            "items": itemsCheckout,
            "shipping_cost": +checkout.shippingCost,
            "taxes": 0,
            "cart_vendor": "shopstar",
            "cart_vendor_order_number": +checkout.draftId,
            "cart_total_price": +checkout.total
        }
    }

    try {

        const data = await auth();

        console.log("Authorization (data):" + data)

        localStorage.setItem("muscleToken", data.access_token);
        const token = data.access_token;

        const response = await cartCashAndPoints(payload, token)

        return response;

    } catch (e) {
        result = e.response;
    }

    return result;
}

export const WalletCartLog = async (cartId, mscInfo) => {

    let result = null;

    try {

        const token = localStorage.getItem("muscleToken");

        const SERVICE_RETAIL = "RETAIL_CART";
        const ACTION_TYPE = "PURCHASE_COMPLETED"

        /*const modelLogging = {
            "id": cartId,
            "cash": mscInfo.cash,
            "points": mscInfo.points
        }*/

        const modelLogging = {
            "id": cartId,
            "service_type": SERVICE_RETAIL,
            "action_type": ACTION_TYPE,
            data: {
                "cash": mscInfo.cash,
                "points": mscInfo.points
            }
        }

        const response = await customerActionsLogging(modelLogging, token);

        return response;
    } catch (e) {
        result = e.response;
    }

    return result;

}
