import React, { Component } from 'react';
import CardComponent from './card/CardComponent';
import Summary from '../summary/Summary';

class PaymentMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      placeHolder: '',
      placeHolderDocumentNumber: '',
      entityIdentificationNumber: '',
      entityName: '',
      department: '',
      province: '',
      district: '',
      addressLine: '',
      addressNumber: '',
      reference: '',
      policies: {
        privacy: false,
        terms: false,
        personalData: false,
      },
      errors: {},
      cardType: '',
      isIbkCard: '',
      installments: 1,
      installmentsList: [],
      freeInstallments: [],
      billingAddress: 'same',
      selectedCardId: "10"
    };
  }

  onSelectPaymentMethod = (type) => {
    this.props.onSelectPaymentMethod(type);
  }

  handleCardChange = (event) => {

  };


  render() {
    const { checkout, start, promo, totalCheckout, miles, enableBenefits, forceBenefits, freeInstallments } = this.props;
    const { selectedCardId } = this.state;

    return (
      <React.Fragment>
        <div className="payment-summary">
          <Summary
            checkout={checkout}
            start={start}
            promo={promo}
            totalCheckout={totalCheckout}
            miles={miles} />
        </div>
        <div className="card step-form">
          <div className="card-body">
            <div className="payment-methods">
              {(checkout.total === 0 && promo) && (
                <div className="card-alert success">
                  ¡Felicitaciones!
                  <br />
                  Tienes el 100% de descuento en tu compra.
                </div>
              )}
              {(checkout.total > 0 || !promo) && (
                <React.Fragment>
                  <h4 className="section-title mt-0">Elige tu método de pago</h4>

                  <h3 style={{ fontSize: "1.15rem" }}>Tarjetas</h3>
                  <div style={{ display: "flex", marginTop: 15, marginBottom: 15, flexWrap: "wrap" }}>
                    <CardComponent
                      id="10"
                      isSelected={selectedCardId === '10'}
                      title="Tarjetas de Crédito"
                      type="credit"
                      onChange={(e) => {
                        this.setState({ selectedCardId: e.target.id });
                        this.onSelectPaymentMethod('4')
                      }}
                    />
                    <CardComponent
                      id="11"
                      isSelected={selectedCardId === '11'}
                      title="Tarjetas de Débito"
                      type="debit"
                      onChange={(e) => {
                        this.setState({ selectedCardId: e.target.id });
                        this.onSelectPaymentMethod('8')
                      }}
                    />
                    <CardComponent
                      id="12"
                      isSelected={selectedCardId === '12'}
                      title="Otras tarjetas"
                      type="other"
                      onChange={(e) => {
                        this.setState({ selectedCardId: e.target.id });
                        this.onSelectPaymentMethod('1')
                      }}
                    />
                  </div>
                  <h3 style={{ fontSize: "1.15rem" }}>Cuotas</h3>
                  <div style={{ display: "flex", marginTop: 15, marginBottom: 15 }}>

                    <CardComponent
                      id="3"
                      isSelected={selectedCardId === '3'}
                      title="Divide tu pago en cuotas con Interbank"
                      type="installments"
                      onChange={(e) => {
                        this.setState({ selectedCardId: e.target.id });
                        this.onSelectPaymentMethod('3')
                      }}
                    />
                    {freeInstallments.length > 0 && (

                      <CardComponent
                        id="7"
                        isSelected={selectedCardId === '7'}
                        title="Meses sin intereses"
                        type="freeinstallments"
                        onChange={(e) => {
                          this.setState({ selectedCardId: e.target.id });
                          this.onSelectPaymentMethod('7')
                        }}
                      />
                    )}
                  </div>

                  <h3 style={{ fontSize: "1.15rem" }}>Otros</h3>

                  <div style={{ display: "flex", flexWrap: "wrap", marginTop: 15, marginBottom: 15 }}>

                    {(enableBenefits || forceBenefits === 'true' || true) && (

                      <CardComponent
                        id="2"
                        isSelected={selectedCardId === '2'}
                        title="Millas Interbank Benefit"
                        type="miles"
                        onChange={(e) => {

                          this.setState({ selectedCardId: e.target.id });
                          this.onSelectPaymentMethod('2')
                        }}
                      />
                    )}

                    {/* <CardComponent
                      id="5"
                      isSelected={selectedCardId === '5'}
                      title="PagoEfectivo"
                      type="pagoefectivo"
                      onChange={(e) => {
                        this.setState({ selectedCardId: e.target.id });
                        this.onSelectPaymentMethod('5')
                      }}
                    /> */}



                    {/* <CardComponent
                      id="99"
                      isSelected={selectedCardId === '99'}
                      title="Interbank App"
                      type="ibkapp"
                      onChange={(e) => {
                        this.setState({ selectedCardId: e.target.id });
                        this.onSelectPaymentMethod('99')
                      }}
                    /> */}

                    {checkout.total <= 500 && (
                      <CardComponent
                        id="6"
                        isSelected={selectedCardId === '6'}
                        title="PLIN/QR"
                        type="plin"
                        onChange={(e) => {
                          this.setState({ selectedCardId: e.target.id });
                          this.onSelectPaymentMethod('6')
                        }}
                      />
                    )}
                  </div>
                </React.Fragment>
              )}

            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default PaymentMethods;
